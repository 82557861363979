import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { AreaRight } from "./components/Area/Area";
import { Menu } from "./components/Menu/Menu";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { PublicRoute } from "./components/PublicRoute/PublicRoute";
import { Checklists } from "./containers/ChecklistPage/Checklists";
import { Objects } from "./containers/ObjectPage/Objects";
import { Audits } from "./containers/AuditPage/Audits";
import { Tasks } from "./containers/TaskPage/Tasks";
import { Inspections } from "./containers/InspectionPage/Inspections";
import { InspectionSingle } from "./containers/InspectionPage/InspectionSingle";
import { ProjectPage } from "./containers/ProjectPage/ProjectPage";
import { ChecklistSingle } from "./containers/ChecklistPage/components/ChecklistSingle";
import { LoginPage } from "./containers/LoginPage/LoginPage";
import { NoMatch } from "./containers/NoMatch/NoMatch";
import { resetTimeActive } from "./api/Naumen";

export function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  const [pageActive, setPageActive] = useState('');

  const [userFirstName, setUserName] = useState(localStorage.getItem("userFirstName"));
  const [userLastName] = useState(localStorage.getItem("userLastName"));
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("userFirstName") === "admin");

  const [AreaRightContent, setAreaRightContent] = useState(false);

  const [isAreaRightActive, setIsAreaRightActive] = useState(false);
  const [containerTypes, setContainerTypes] = useState(false)
  const [isContainerTypes, setContainerTypesShow] = useState(false)

  const [isMenuActive, setMenuActive] = useState(false)

  useEffect(() => {

    resetTimeActive()

    if (!isContainerTypes) {
      setContainerTypes(false)
    }
    if (!isAreaRightActive) {
      setAreaRightContent(false)
    }
  }, [isAreaRightActive, isContainerTypes])

  let bodyClass = 'Body'

  if (pageActive === 'inspection') {
    bodyClass = 'Body BodyFull'
  }

  if (!isLoggedIn) {
    bodyClass = 'Body w-100'
  }

  return (
    <Router>
      <div className="App">
      
      {pageActive !== 'inspection' ? 
      <Header
          userFirstName={userFirstName} 
          userLastName={userLastName} 
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setIsAdmin={setIsAdmin}
          isMenuActive={isMenuActive} 
          setMenuActive={setMenuActive} 
      />
      : ''}

      <div className={ isMenuActive ? 'flex jbetween menuActive' : 'flex jbetween' }>

      { pageActive !== 'inspection' && isLoggedIn ? <Menu /> : '' }
      
        <div className={ bodyClass }> 

        <main>
          <Switch>

            <Route
              exact
              path="/"
              render={() => {
                if (isLoggedIn) return <Redirect to="/checklist" />;
                return <Redirect to="/login" />;
              }}
            />

            <PublicRoute isLoggedIn={isLoggedIn} path="/login">
              <LoginPage
                pageActive={pageActive}
                setPageActive={setPageActive} 
                setIsLoggedIn={setIsLoggedIn}
                setUserName={setUserName}
                setIsAdmin={setIsAdmin}
              />
            </PublicRoute>

            <PrivateRoute isLoggedIn={isLoggedIn} path="/checklist/:checklistUUID">
              <ChecklistSingle 
                isAdmin={isAdmin} 
                pageActive={pageActive}
                setPageActive={setPageActive} 
                setAreaRightContent={setAreaRightContent}
                setIsAreaRightActive={setIsAreaRightActive}
                containerTypes={containerTypes} 
                setContainerTypes={setContainerTypes}
                setContainerTypesShow={setContainerTypesShow}
                isContainerTypes={isContainerTypes}
              />
            </PrivateRoute>

            <PrivateRoute isLoggedIn={isLoggedIn} path="/checklist">
              <Checklists 
                isAdmin={isAdmin} 
                pageActive={pageActive} 
                setPageActive={setPageActive} 
              />
            </PrivateRoute>

            <PrivateRoute isLoggedIn={isLoggedIn} path="/object">
              <Objects isAdmin={isAdmin} 
                pageActive={pageActive}
                setPageActive={setPageActive} 
                setAreaRightContent={setAreaRightContent}
                setIsAreaRightActive={setIsAreaRightActive}
              />
            </PrivateRoute>

            <PrivateRoute isLoggedIn={isLoggedIn} path="/audit">
              <Audits isAdmin={isAdmin} 
                pageActive={pageActive}
                setPageActive={setPageActive} 
                setAreaRightContent={setAreaRightContent}
                setIsAreaRightActive={setIsAreaRightActive}
              />
            </PrivateRoute>

            <PrivateRoute isLoggedIn={isLoggedIn} path="/task">
              <Tasks isAdmin={isAdmin} 
                pageActive={pageActive}
                setPageActive={setPageActive} 
                setAreaRightContent={setAreaRightContent}
                setIsAreaRightActive={setIsAreaRightActive}
              />
            </PrivateRoute>

             <PrivateRoute isLoggedIn={isLoggedIn} path="/inspection/:inspectionUUID">
              <InspectionSingle 
                isAdmin={isAdmin} 
                pageActive={pageActive}
                setPageActive={setPageActive}
              />
            </PrivateRoute>

            <PrivateRoute isLoggedIn={isLoggedIn} path="/inspection">
              <Inspections isAdmin={isAdmin} 
                pageActive={pageActive}
                setPageActive={setPageActive}  
                setAreaRightContent={setAreaRightContent}
                setIsAreaRightActive={setIsAreaRightActive}
              />
            </PrivateRoute>

            <PrivateRoute isLoggedIn={isLoggedIn} path="/project">
              <ProjectPage isAdmin={isAdmin} 
                pageActive={pageActive} 
                setPageActive={setPageActive} 
              />
            </PrivateRoute>

            <Route path="/404">
              <NoMatch 
                pageActive={pageActive}
                setPageActive={setPageActive} 
              />
            </Route>

            <Route path="*"
              render={({ location }) => {
                if (location.pathname === "/sd/application-Mobit/index.html") {
                  return <Redirect to="/checklist" />
                } else {
                  return <Redirect to={{
                    pathname: '/404',
                    from: location
                  }} />
                }
                
              }}
            />

          </Switch>
        </main>

        <AreaRight isActive={isAreaRightActive} setIsActive={setIsAreaRightActive} content={AreaRightContent} />

        <Footer year={new Date().getFullYear()} isLoggedIn={isLoggedIn} />
      </div>
      </div>
      </div>
    </Router>
  );
}
