import React, {useState, useEffect} from 'react';
import './Tasks.css';
import { TasksItem } from './TasksItem';
import { FormAddTask } from './forms/FormAddTask';
import TasksBlock from './TasksBlock';
import { 
  getTasks, 
  getTaskGroups, 
} from './TasksQuery';
import Button from '@mui/material/Button';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import {
  Redirect,
} from "react-router-dom";


export const Tasks = (props) => {

  const [ isFormAddGroup, setFormAddGroup ] = useState(false)
  const [ isFormAddTask, setFormAddTask ] = useState(false)
  const [ isListChoosed, setListChoosed ] = useState(false)


  const handlerToggleFormAddTask = (item) => {
  	return;
    setFormAddGroup(false)
    setFormAddTask(!isFormAddTask)
    props.setAreaRightContent('')

    if (item.UUID) {
      props.setAreaRightContent(<FormAddTask { ...props } item={item} isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddTask={setFormAddTask} />)
    } else {
      props.setAreaRightContent(<FormAddTask { ...props } isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddTask={setFormAddTask} />)
    }
    
    props.setIsAreaRightActive(true)

  }

  return (
    <div className="bodyContent100">
      <div>

        <div className="flex acenter jbetween">
          <h1>Tasks</h1>
          <div>
          <Button className="btn" variant="contained" onClick={handlerToggleFormAddTask}>+ Add Task</Button>
          </div>
        </div>

        <TasksBlock { ...props } parent={0} isListChoosed={isListChoosed} setListChoosed={setListChoosed} handlerToggleFormAddTask={handlerToggleFormAddTask}
         />

    </div>
    </div>
  );
};
