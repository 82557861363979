import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MdDelete, MdDragIndicator } from "react-icons/md";
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Moment from 'moment';

import {   
  getInspections 
} from '../InspectionsQuery';

function PaperComponent(props) {
  return (
      <Paper {...props} />
  );
}

const MoreInspection = (props) => {

  const history  = useHistory()

  const Redirect = () => {
    props.handlerInspectionMoreClose()
    history.push('/inspection/' + props.item.UUID)
  }

  return (
  	<div key={props.index ?? '1'}>

      <div className="btn-absolute-left" onClick={props.handlerInspectionMoreClose}></div>

      <div className="text-center">
      <InputLabel>
        Details Inspection
      </InputLabel>
      </div>

      <div className="mt-2 mb-3"></div>

      <Typography gutterBottom variant="h5" component="div">
        {props.item.creationDate ? Moment(props.item.creationDate).format('d MMM y') : ''} / {props.item.responsibleEmployee.title ?? ''}
      </Typography>

      <Stack spacing={2} direction="row" className="mt-2">
        <Button className="btn btn-w-50" variant="contained" onClick={ Redirect }>Continue</Button>
        
      </Stack>

      <div className="hr mx--3"></div>

      <div className="block-border">

      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                <b>Template</b>
              </InputLabel>
            </div>
            <div>
              {props.item.title}
            </div>
        </Grid>
      </Grid>
      <div className="mt-1"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                <b>Status</b>
              </InputLabel>
            </div>
            <div>
              {props.item.status ? 'Completed' : 'In work'}
            </div>
        </Grid>
      </Grid>
      <div className="mt-1"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                <b>Score</b>
              </InputLabel>
            </div>
            <div>
              {props.item.score ?? 0}%
            </div>
        </Grid>
      </Grid>
      <div className="mt-1"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                <b>Items completed</b>
              </InputLabel>
            </div>
            <div>
              0/0
            </div>
        </Grid>
      </Grid>

      <div className="mt-1"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                <b>Started</b>
              </InputLabel>
            </div>
            <div>
              {props.item.creationDate ? Moment(props.item.creationDate).format('d MMM y H:mm') : ''}
            </div>
        </Grid>
      </Grid>
      
      <div className="mt-1"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                <b>Responsible</b>
              </InputLabel>
            </div>
            <div>
              {props.item.responsible ? props.item.responsible.title : 'All users'}
            </div>
        </Grid>
      </Grid>

      </div>

    </div>

  );
};

export default MoreInspection