import React from 'react';
import { MdCollections } from "react-icons/md";

import Button from '@mui/material/Button';

const Media = (props) => {

    return (
        <>

	         <div className="QuizContent__title">
	         	{props.item.title}
	         </div>
	         <div className="QuizContent__body">
		        
		        <Button className="btn w-100 flex acenter" variant="contained" onClick={()=>props.fileInputRef.current.click()}>
		        	<span className="flex acenter fs-20"><MdCollections /></span> &nbsp;&nbsp;Add media
				</Button>

	         </div>
	         
        </>
      );
  
};

export default Media