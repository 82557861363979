import React, {useState} from 'react';

const Media = (props) => {

    return (
        <div className="QuizFileItems">
        	{props.files?.map((file, index) => {
        		return (<div key={index} className="QuizFileItem">
        			<img src={file.path} alt="" />
        		</div>)
        	})}
        </div>
      );
  
};

export default Media
