import React, { useState } from 'react';
import { updateQuestion } from '../Queries';

const Number = (props) => {

	const Save = (e) => {

		const answer = e.target.value

		// сохраняем answer
		// только если рабочая версия

		if (props.work) {
			updateQuestion(props.item.UUID, {'answer': answer}).then((item) => {
				if (item.data.UUID !== null && item.data.UUID !== undefined) {
					props.setSaveResult(true)
				}
			}).catch(() => {
				props.setSaveResult(false)
			})

		}

	}

    return (
        <>

	         <div className="QuizContent__title">
	         	{props.item.title}
	         </div>
	         <div className="QuizContent__body">
		         <input type="number" className="form-control" onChange={ Save } onBlur={ Save } value={ props.item.answer } />
	         </div>
	         
        </>
      );
  
};

export default Number