import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { changeRequired, changeShowDate, changeShowTime } from './ControlTypeQuery';

export const TypeDateTime = ({ 
		questionItem, 
		getSettingsType, 
		setAreaRightContent, 
		setIsAreaRightActive,
		setQuizQuestions 
	}) => {
	
	const [
		isCheckedRequired, 
		setCheckedRequired
	] = useState(questionItem.required ?? false);

	const [
		isCheckedShowDate, 
		setCheckedShowDate
	] = useState(questionItem.showDate ?? false);
	
	const [
		isCheckedShowTime, 
		setCheckedShowTime
	] = useState(questionItem.showTime ?? false);


	return (
		<div className="flex acenter">
		    <FormControlLabel
		      value="1"
	          control={<Checkbox checked={isCheckedRequired} onChange={() => changeRequired({
	          	questionUUID: questionItem.UUID, 
	          	setCheckedRequired,
	          	isCheckedRequired,
	          	setQuizQuestions
	          })} name="required" />}
	          label="Required"
	        />

	        <FormControlLabel
	          value="2"
	          control={<Checkbox checked={isCheckedShowDate} onChange={() => changeShowDate({
	          	questionUUID: questionItem.UUID, 
	          	setCheckedShowDate,
	          	isCheckedShowDate,
	          	setQuizQuestions
	          })} name="showDate" />}
	          label="Date"
	        />

	        <FormControlLabel
	          value="3"
	          control={<Checkbox checked={isCheckedShowTime} onChange={() => changeShowTime({
	          	questionUUID: questionItem.UUID, 
	          	setCheckedShowTime,
	          	isCheckedShowTime,
	          	setQuizQuestions
	          })} name="showTime" />}
	          label="Time"
	        />
		</div>
	);

};
