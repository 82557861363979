import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Slider2 from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { updateQuestion } from '../Queries';

function ValueLabelComponent(props) {
		const { children, value } = props;
		
		return (
		    <Tooltip enterTouchDelay={0} placement="top" arrow title={value}>
		      {children}
		    </Tooltip>
		);
}

const Slider = (props) => {

	const [inputMin, setInputMin] = useState(0);
	const [inputMax, setInputMax] = useState(0);
	const [inputReal, setInputReal] = useState(props.item.answer);
	const [inputSlideInc, setInputSlideInc] = useState(0);
	
	useEffect(() => {

		if (!props.work) {
			setInputReal(2)
		}

		setInputMin(props.item.min ?? 1)
		setInputMax(props.item.max ?? 1)
		setInputSlideInc(props.item.slideIncrement ?? 1)

	}, [props.item, props.quizQuestions])

	const handleSliderChange = (event, newValue) => {
	    setInputReal(newValue);

	    if (props.work) {
			updateQuestion(props.item.UUID, {'answer': newValue}).then((item) => {
				if (item.data.UUID !== null && item.data.UUID !== undefined) {
					props.setSaveResult(true)
				}
			}).catch(() => {
				props.setSaveResult(false)
			})
			
		}

	};

    return (
        <>

	         <div className="QuizContent__title">
	         	{props.item.title}
	         </div>
	         <div className="QuizContent__body">
		        
		        <Slider2 key={`slider-${props.item.UUID}`} 
					defaultValue={inputReal} 
					valueLabelDisplay="auto" 
					components={{
		               ValueLabel: ValueLabelComponent,
		            }} 
		            step={inputSlideInc} 
		            min={inputMin} 
		            max={inputMax} 
		            onChange={handleSliderChange} 
		            className="pt-4 mb--2" 
			    />

	            <Grid container spacing={0}>
				  <Grid item xs={4}>{inputMin}</Grid>
			  	  <Grid item xs={4} sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '19px' }}>{inputReal}</Grid>
				  <Grid item xs={4} sx={{ textAlign: 'right' }}>{inputMax}</Grid>
				</Grid>

	         </div>

        </>
      );
  
};

export default Slider