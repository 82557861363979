import React, { useState, useEffect } from 'react';
import { getMultipleVariants } from '../Queries';
import MultipleItem from './MultipleItem';

const Multiple = (props) => {

	const [ isMounted, setMounted ] = useState(true)
	let groupMultipleUUID = ''

	if (props.item.groupMultiple.UUID !== undefined) {
		groupMultipleUUID = props.item.groupMultiple.UUID
	}
	if (props.item.groupMultiple[0] !== undefined) {
		groupMultipleUUID = props.item.groupMultiple[0].UUID
	}

	useEffect(() => {

		if (isMounted && props.isLoading && groupMultipleUUID) {

			getMultipleVariants({ groupMultiple: groupMultipleUUID, removed: 0 }).then(items => {

				const variantsSort = items.sort((a, b) => a.sort - b.sort)
			    props.setMultipleVariants(variantsSort);
			    props.setLoading(false)
			    props.setMounted(false)			    

			}).catch(err => {})

		}
		
	}, [props.multipleVariants])

    return (
        <>

	         <div className="QuizContent__title">
	         	{props.item.title} 
	         </div>
	         <div className="QuizContent__body">
		         <div className="QuizContent__btns">
		          
			        {props.multipleVariants.length > 0 ? props.multipleVariants?.map((variant, index) => {
						return (<MultipleItem variant={variant} {...props} setSaveResult={props.setSaveResult} />)
				    }) : ''}
		         	
		         </div>
	         </div>
	         
        </>
      );
  
};

export default Multiple