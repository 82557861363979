import { Link } from 'react-router-dom';
import { MdDelete, MdDragIndicator } from "react-icons/md";
import MoreAudit from "./more/MoreAudit";
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import Swal from 'sweetalert2'
import { RiPencilFill } from "react-icons/ri";
import { 
  deleteAudit 
} from './AuditsQuery';

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

export const AuditsItem = (props) => {
  
  const handleDelete = () => {
    return;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert Audit!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        deleteAudit(props.item.UUID).then(res => {
          if (res === true) {
            props.setAudits([...props.audits.slice(0, props.index), ...props.audits.slice(props.index + 1)]);
            /*Swal.fire(
              'Deleted!',
              'Your checklist has been deleted.',
              'success'
            )*/
          } else {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Запись не существует или у вас нет прав на ее удаление'
            })

          }
        }).catch(err => {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err
          })

        })

      }
    })

  }
  
  const handlerAuditMoreClose = () => {

    props.setAreaRightContent('')
    props.setIsAreaRightActive(false)

  }

  const handlerAuditMore = () => {
    return;
    props.setAreaRightContent('')
    props.setAreaRightContent(<MoreAudit { ...props } handlerAuditMoreClose={handlerAuditMoreClose} />)
    props.setIsAreaRightActive(true)

  }

  return (
  		<div className="table-default-tr flex jbetween">
  			
            <div className="table-default-td table-default-title" onClick={handlerAuditMore}>
                <div className="checklist-title">{props.item.title}</div>
                <div className="checklist-descritpion">{ props.item.description ?? '' }</div>
            </div>

            <div className="table-default-td" onClick={handlerAuditMore}>
              {props.item.number ?? '0'}
            </div>

            <div className="table-default-td" onClick={handlerAuditMore}>{props.item.responsible === null
              ? 
              'All users'
              : props.item.responsible.title
            }</div>

            <div className="table-default-td" onClick={handlerAuditMore}>
            
            <ReactTimeAgo date={Date.parse(props.item.creationDate.replace(/ /g, 'T').replace(/\./g, '-'))} locale="en-US" />
            	
            </div>
            
            <div className="table-default-td table-default-btns-object">
              <div className="flex jend">
                <div className="btn-delete mr-1" onClick={() => props.handlerToggleFormAddAudit(props.item)}><RiPencilFill /></div>
                <div className="btn-delete" onClick={handleDelete}><MdDelete /></div>
              </div>
            </div>
           
      </div>
  );
};
