import React, {useState, useEffect} from 'react';
import './Audits.css';
import { AuditsItem } from './AuditsItem';
import { FormAddAudit } from './forms/FormAddAudit';
import AuditsBlock from './AuditsBlock';
import { 
  getAudits, 
  getAuditGroups, 
} from './AuditsQuery';
import Button from '@mui/material/Button';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import {
  Redirect,
} from "react-router-dom";


export const Audits = (props) => {

  const [ isFormAddGroup, setFormAddGroup ] = useState(false)
  const [ isFormAddAudit, setFormAddAudit ] = useState(false)
  const [ isListChoosed, setListChoosed ] = useState(false)


  const handlerToggleFormAddAudit = (item) => {
  	return;
    setFormAddGroup(false)
    setFormAddAudit(!isFormAddAudit)
    props.setAreaRightContent('')

    if (item.UUID) {
      props.setAreaRightContent(<FormAddAudit { ...props } item={item} isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddAudit={setFormAddAudit} />)
    } else {
      props.setAreaRightContent(<FormAddAudit { ...props } isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddAudit={setFormAddAudit} />)
    }
    
    props.setIsAreaRightActive(true)

  }

  useEffect(() => {
    props.setPageActive('audits')
  })

  return (
    <div className="bodyContent100">
      <div>

        <div className="flex acenter jbetween">
          <h1>Audits</h1>
          <div>
          <Button className="btn" variant="contained" onClick={handlerToggleFormAddAudit}>+ Add Audit</Button>
          </div>
        </div>

        <AuditsBlock { ...props } parent={0} isListChoosed={isListChoosed} setListChoosed={setListChoosed} handlerToggleFormAddAudit={handlerToggleFormAddAudit}
         />

    </div>
    </div>
  );
};
