import axios from 'axios';
import { useQuery } from 'react-query'
import {Get, Find, naumenEdit, naumenGet, naumenGetSingle, naumenCreate, naumenDelete, naumenDestroy, Request} from '../../api/Naumen';

export const useQuestions = (query = '', attrs = '') => {
  return Find({
    key: 'useQuestions', 
    metaClass: 'mnRequirement$requirement', 
    query, 
    attrs
  });

}

export const getQuestionsForParentPage = (query) => {
 return naumenGet('mnRequirement', query);
}
export const getData = (UUID) => {
 return naumenGet('mnMultipleResp', { UUID });
}

export const getChecklistPages = (query = '', attrs = '') => {
  return naumenGet('mnChecklist$pageCheckList', query);
}

export const updatePage = (pageUUID, query) => {
  return naumenEdit(pageUUID, query);
}
export const updateQuestion = (questionUUID, query) => {
  return naumenEdit(questionUUID, query);
}
export const updateSection = (sectionUUID, query) => {
  return naumenEdit(sectionUUID, query);
}
export const updateChecklist = (checklistUUID, query) => {
  return naumenEdit(checklistUUID, query);
}

export const deleteQuestion = (UUID) => {
  return naumenDestroy(UUID)
}

export const saveSortablePages = (pages) => {
  pages?.map((page, sort) => {
    if (page.sort !== sort) {
      naumenEdit(page.UUID, {sort});
    }
  })
}

export const saveSortableQuestions = (questions, setQuizQuestions) => {
  
  let res = questions?.map((question, sort) => {

    if (question.sort !== sort) {
      naumenEdit(question.UUID, {sort}).then(() => {
          setQuizQuestions([])
      });
    }
  })
  //document.querySelectorAll('[name="title"]')[1].focus()
}

export const saveSortableChecklists = (checklists, setChecklists) => {
  
  let res = checklists?.map((checklist, sort) => {

    if (checklist.sort !== sort) {
      naumenEdit(checklist.UUID, {sort}).then((it) => {
          checklists = checklists.map((item, index) => {
            if (item.UUID === it.data.UUID) {
              return it.data
            } else {
              return item
            }
          })
          //setChecklists(checklists)
      });
    }
  })

}

export const saveSortableVariants = (variants) => {
  variants?.map((variant, sort) => {
    if (variant.sort !== sort) {
      naumenEdit(variant.UUID, {sort});
    }
  })
}

export const deleteChecklistPage = (UUID) => {
  naumenGet('mnRequirement', {parentPage: UUID})
    .then(questions => {
      if (questions.length > 0) {
        questions.map(question => {
          return naumenDestroy(question.UUID)
        })
        setTimeout(() => {
          return naumenDestroy(UUID)
        }, 500)
      }
    })
    //alert(UUID)
  return true;
}

export const createChecklistPage = (query = [], attrs = '') => {
  query.title = query.title ?? query.name
  query.name = query.name ?? query.title
  query.sort = 999
  query.metaClass = query.metaClass ?? 'mnChecklist$pageCheckList'

  return naumenCreate('mnChecklist$pageCheckList', query)

}


export const createChecklistQuestion = (query = [], attrs = '') => {
  query.title = query.title ?? 'Type Question'
  query.metaClass = query.metaClass ?? 'mnRequirement$requirement'
  query.sort = 999
  return naumenCreate('mnRequirement$requirement', query, attrs);
}

export const createChecklistSection = (query = [], attrs = '') => {
  query.title = query.title ?? 'Type Section'
  query.metaClass = query.metaClass ?? 'mnRequirement$reqGroup'
  query.sort = 999
  return naumenCreate('mnRequirement$reqGroup', query, attrs);
}

export const getChecklists = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('mnChecklist$cList', query);

}

export const getChecklist = (UUID) => {
  return naumenGetSingle(UUID);

}

export const getTypes = (query = '') => {
  return naumenGet('nmReqTypes', query);

}

export const getMultipleGroups = (query = '', attrs = '') => {
  return naumenGet('nmGroupMultipl$groupMultiple', query);

}

export const getMultipleVariants = (query = '') => {
  return naumenGet('mnMultipleResp$mnReqConf', query);

}

export const useMultipleVariants = (query = '') => {

  return Find({
    key: 'useMultipleVariants', 
    metaClass: 'mnMultipleResp$mnReqConf'
  });

}


export const useMultiples = (query) => {

  const queryData = Request({ method: 'exec', query: {
      "func":"modules.mnMobitMain.mnMultipleRespColor"
      },
      addon: "&params='"+query+"'"
    });

  return useQuery('useMultiples', () => {
    return axios(queryData)
      .then(res => res.data)
      .catch(err => {
        throw new Error(err)
      })
  }, {
    refetchOnWindowFocus: false,
  })

}

export const createChecklist = (query = [], attrs = '') => {
  query.title = query.title ?? query.name
  query.name = query.name ?? query.title
  query.metaClass = query.metaClass ?? 'mnChecklist$cList'
  query.sort = 999
  return naumenCreate('mnChecklist$cList', query, attrs);
}

export const deleteChecklist = (UUID) => {
  return naumenDelete(UUID);
}
