import { Link } from 'react-router-dom';
import { MdDelete, MdDragIndicator } from "react-icons/md";
import MoreObject from "./more/MoreObject";
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import Swal from 'sweetalert2'
import { RiPencilFill } from "react-icons/ri";
import { 
  deleteObject 
} from './ObjectQuery';

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

export const ObjectItem = (props) => {
  
  const handleDelete = () => {
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert object!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        deleteObject(props.item.UUID).then(res => {
          if (res === true) {
            props.setObjects([...props.objects.slice(0, props.index), ...props.objects.slice(props.index + 1)]);
            /*Swal.fire(
              'Deleted!',
              'Your checklist has been deleted.',
              'success'
            )*/
          } else {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Запись не существует или у вас нет прав на ее удаление'
            })

          }
        }).catch(err => {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err
          })

        })

      }
    })

  }
  
  const handlerObjectMoreClose = () => {

    props.setAreaRightContent('')
    props.setIsAreaRightActive(false)

  }

  const handlerObjectMore = () => {

    props.setAreaRightContent('')
    props.setAreaRightContent(<MoreObject { ...props } handlerObjectMoreClose={handlerObjectMoreClose} />)
    props.setIsAreaRightActive(true)

  }

  return (
  		<div className="table-default-tr flex jbetween">
  			
            <div className="table-default-td table-default-title" onClick={handlerObjectMore}>
                <div className="checklist-title">{props.item.title}</div>
                <div className="checklist-descritpion">{ props.item.description ?? '' }</div>
            </div>

            <div className="table-default-td" onClick={handlerObjectMore}>{props.item.responsible === null
              ? 
              'All users'
              : props.item.responsible.title
            }</div>

            <div className="table-default-td" onClick={handlerObjectMore}>
            
            <ReactTimeAgo date={Date.parse(props.item.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-'))} locale="en-US" />
            	
            </div>
            <div className="table-default-td" onClick={handlerObjectMore}>{props.item.adress ?? ''}</div>
            
            <div className="table-default-td table-default-btns-object">
              <div className="flex jend">
                <div className="btn-delete mr-1" onClick={() => props.handlerToggleFormAddObject(props.item)}><RiPencilFill /></div>
                <div className="btn-delete" onClick={handleDelete}><MdDelete /></div>
              </div>
            </div>
           
      </div>
  );
};
