import axios from 'axios';
import { useQuery } from 'react-query'
import {Get, Find, naumenEdit, naumenGet, naumenGetSingle, naumenCreate, naumenDelete, naumenDestroy, Request} from '../../api/Naumen';

export const createTeam = (query = {}, attrs = '') => {
  query.metaClass = query.metaClass ?? 'team$team'
  return naumenCreate('team$team', query);
}

export const startInspection = (checklistTempUUID) => {

  
  // Копируем шаблон чеклиста в чеклисты
  naumenGet('mnChecklist$cList', {UUID: checklistTempUUID}).then((checklistTemp) => {
    if (checklistTemp[0] !== null && checklistTemp[0] !== undefined) {

      const itemAttrs = checklistTemp[0]
      let checklistTempNew = {}

      Object.keys(itemAttrs).filter((key) => {

        // отсеиваем ненужные атрибуты
        if (key !== 'creationDate' && 
            key !== 'stateStartTime' && 
            key !== 'lastModifiedDate' && 
            key !== 'folders' && 
            key !== 'questions' && 
            key !== 'questionsPage' && 
            key !== 'cLGroups' && 
            key !== 'childs' && 
            key !== 'UUID'
        ) {

          // Если это объект
          if ( typeof itemAttrs[key] === 'object' || Array.isArray(itemAttrs[key]) ) {
            if (itemAttrs[key] !== null) {
               checklistTempNew[key] = itemAttrs[key].UUID
            }

            // Если это многомерный массив
            if (Array.isArray(itemAttrs[key]) && itemAttrs[key][0] !== null && itemAttrs[key][0] !== undefined) {
              checklistTempNew[key] = itemAttrs[key].map((item) => {
                return [item.UUID]
              })
            }

          } else if (itemAttrs[key] !== null && itemAttrs[key] !== '') {
            checklistTempNew[key] = itemAttrs[key]
          }
        }

      })

      // Создаем чеклист для проверки
      checklistTempNew.metaClass = 'mnChecklist$checkListWork'
      checklistTempNew.responsibleEmployee = localStorage.getItem('UUID')

      naumenCreate('mnChecklist$checkListWork', checklistTempNew).then((checklist) => {
        if ( typeof checklist === 'object' || Array.isArray(checklist) ) {
          
          // создаем проверку и цепляем ее с шаблоном и конечным чеклистом
          let query = {}
          query.metaClass = 'task$mnInspection'
          query.responsibleEmployee = localStorage.getItem('UUID')
          query.title = checklist.title
          query.mnTmpCheckList = checklistTempUUID
          query.mnOriginCheckL = checklist.UUID

          // получаем страницы чеклиста
          naumenGet('mnChecklist$pageCheckList', {parent: checklistTempUUID}).then((pagesTemp) => {
            if (pagesTemp) {

              let pageTempNew = {}

              // создаем все страницы для чеклиста
              pagesTemp.map(function(pageTemp) {

                Object.keys(pageTemp).filter((key) => {
                  // отсеиваем ненужные атрибуты
                  if (key !== 'creationDate' && 
                      key !== 'lastModifiedDate' && 
                      key !== 'questionsPage' && 
                      key !== 'questions' && 
                      key !== 'stateStartTime' && 
                      key !== 'childs' && 
                      key !== 'folders' && 
                      key !== 'UUID'
                  ) {

                    // Если это объект
                    if ( typeof pageTemp[key] === 'object' || Array.isArray(pageTemp[key]) ) {
                      if (pageTemp[key] !== null) {
                         pageTempNew[key] = pageTemp[key].UUID
                      }

                      // Если это многомерный массив
                      if (Array.isArray(pageTemp[key]) && pageTemp[key][0] !== null && pageTemp[key][0] !== undefined) {
                        pageTempNew[key] = pageTemp[key].map((item) => {
                          return [item.UUID]
                        })
                      }

                    } else if (pageTemp[key] !== null && pageTemp[key] !== undefined && pageTemp[key] !== '') {
                      pageTempNew[key] = pageTemp[key]
                    }
                  }

                })

                pageTempNew.metaClass = 'mnChecklist$pageWork'
                pageTempNew.parent = checklist.UUID

                // создаем страницу для чеклиста
                naumenCreate('mnChecklist$pageCheckList', pageTempNew).then((page) => {

                  // для данной страницы !шаблона! находим ВОПРОСЫ
                  // копируем и цепляем к только что созданной странице

                  naumenGet('mnRequirement$requirement', {parentPage: pageTemp.UUID}).then((requirements) => {

                    requirements.map((requirement) => {

                      let requirementNew = {}

                      Object.keys(requirement).filter((key) => {
                        // отсеиваем ненужные атрибуты
                        if (key !== 'creationDate' && 
                            key !== 'lastModifiedDate' && 
                            key !== 'stateStartTime' && 
                            key !== 'questionsPage' && 
                            key !== 'childs' && 
                            key !== 'reqGroups' && 
                            key !== 'parent' && 
                            key !== 'folders' && 
                            key !== 'UUID'
                        ) {

                          // Если это объект
                          if ( typeof requirement[key] === 'object' || Array.isArray(requirement[key]) ) {
                            if (requirement[key] !== null && requirement[key] !== undefined && requirement[key].UUID !== undefined && requirement[key].UUID !== null) {
                              requirementNew[key] = requirement[key].UUID
                            }

                            // Если это многомерный массив
                            if (Array.isArray(requirement[key]) && requirement[key][0] !== null && requirement[key][0] !== undefined) {
                              requirementNew[key] = requirement[key].map((item) => {
                                return [item.UUID]
                              })
                            }

                          } else if (requirement[key] !== null && requirement[key] !== '' && requirement[key] !== undefined) {
                            requirementNew[key] = requirement[key]
                          }
                        }

                      })

                      // создаем рабочий вопрос
                      requirementNew.metaClass = 'mnRequirement$reqWork'
                      requirementNew.parentPage = page.UUID

                      naumenCreate('mnRequirement$requirement', requirementNew).then((requirementWork) => {
                        // 
                      })

                      if (requirement.groupMultiple !== null && requirement.groupMultiple !== undefined) {
                        // находим группу множественного выбора
                        // и копируем группу
                        naumenGet('nmGroupMultipl$groupMultiple', {UUID: requirement.groupMultiple.UUID}).then((groupMultipleTemp) => {

                          if (groupMultipleTemp[0] !== undefined && groupMultipleTemp[0] !== null) {
                            
                            groupMultipleTemp[0].metaClass = 'nmGroupMultipl$groupMultiWork'
                            //let multiples = 
                            //groupMultipleTemp[0].multiple = multiples
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            // Тут надо сделать сборку UUID вариантов для множественного выбора
                            naumenCreate('nmGroupMultipl$groupMultiple', groupMultipleTemp[0]).then((groupMultipleWork) => {
                              // Успешно добавлена группа
                            })

                          }

                        })

                      }

                    })

                  })

                  // для данной страницы !шаблона! находим СЕКЦИИ
                  // копируем и цепляем к только что созданной странице

                  naumenGet('mnRequirement$reqGroup', {parentPage: pageTemp.UUID}).then((sections) => {

                    sections.map((section) => {

                      // создаем рабочую секцию
                      section.metaClass = 'mnRequirement$reqGroupWork'
                      section.parentPage = page.UUID
                      naumenCreate('mnRequirement$reqGroup', section).then((sectionWork) => {

                      })

                    })

                  })

                })

              })

            }

          })

          naumenCreate('task$mnInspection', query).then((inspection) => {

            window.location.href = "/inspection/" + inspection.UUID;

          })
        }
        
      })
    }
  })
  
  
  return false;
  //query.ParentString = query.ParentString ?? 0
  //return naumenCreate('task$mnInspection', query);
}

export const getInspection = (UUID) => {
  return naumenGetSingle(UUID);
}

export const getChecklist = (UUID) => {
  return naumenGetSingle(UUID);
}

export const getChecklistPages = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('mnChecklist$pageWork', query);
}

export const getInspections = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('task$mnInspection', query);
}

export const getTeams = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('team$team', query);
}

export const getUsers = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('employee$employee', query);
}

export const createInspection = (query = {}, attrs = '') => {
	query.ParentString = query.ParentString ?? 0
    return naumenCreate('task$mnInspection', query);
}

export const updateInspection = (UUID, query = {}, attrs = '') => {
    return naumenEdit(UUID, query);
}

export const deleteInspection = (UUID) => {
  return naumenDelete(UUID);
}


