import React, { useState, useEffect } from 'react';

const Slider = (props) => {

	const [inputReal, setInputReal] = useState(props.item.answer);

    return (
        <>

	         <div className="QuizContent__title">
	         	{props.item.title}
	         </div>
	         <div className="QuizContent__body">
		        
		        {inputReal}

	         </div>

        </>
      );
  
};

export default Slider