import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete, MdDragIndicator } from "react-icons/md";
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'

import { startInspection } from '../../InspectionPage/InspectionsQuery';

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

export const ChecklistCard = ({
  title,
  index,
  item,
  setRedirect,
  isAdmin,
  handleDelete,
  provided,
}) => {
  
  const [ startDisabled, setStartDisabled ] = useState(false)

  const handleStartInspection = (e) => {
    e.preventDefault()
    setStartDisabled(true)
    startInspection(item.UUID)
  }

  return (
  		<div className="table-default-tr flex jbetween">
  			
            <div className="table-default-td table-default-title" onClick={() => {setRedirect('/checklist/' + item.UUID)}}>
            	<Link className="checklist-title" to={`/checklist/${item.UUID}`}>{item.title}</Link>
            	<div className="checklist-descritpion">{ item.description ?? '' }</div>
            </div>
            <div className="table-default-td" onClick={() => {setRedirect('/checklist/' + item.UUID)}}>
            
            <ReactTimeAgo date={Date.parse(item.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-'))} locale="en-US" />
            	
            </div>
            <div className="table-default-td" onClick={() => {setRedirect('/checklist/' + item.UUID)}}>No</div>
            <div className="table-default-td" onClick={() => {setRedirect('/checklist/' + item.UUID)}}>{item.responsible === null
            	? 
            	'All users'
            	: ''
            }</div>
            <div className="table-default-td">
            	{startDisabled ? 
            	<button className="link-btn-small" onClick={handleStartInspection} disabled="disabled">Start&nbsp;inspection</button>
            	: <button className="link-btn-small" onClick={handleStartInspection}>Start&nbsp;inspection</button>}
            	
            </div>
            <div className="table-default-td table-default-btns"><div className="btn-delete" onClick={handleDelete}><MdDelete /></div></div>
            
        </div>
  );
};
