import React, { useState, useEffect } from 'react';
import Checkbox2 from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const Checkbox = (props) => {

	const [
		isCheckedRequired, 
		setCheckedRequired
	] = useState(props.item.answer === "1" ? true : false);

    return (
        <>	  

	         <div className="QuizContent__body">
		        <FormControlLabel
		          value="1"
		          control={<Checkbox2 checked={ isCheckedRequired } />}
		          label={props.item.title}
		        />
	         </div>
	         
        </>
      );
  
};

export default Checkbox