import React from 'react';
import { QuestionItem } from './QuestionItem';
import { SectionItem } from './SectionItem';

export const SectionQuestionItems = (props) => {

  if (props.questionItem.UUID === undefined || props.questionItem.UUID === null) {
    props.questionItem = props.questionItem.props.questionItem
  }

  if (props.questionItem.metaClass === 'mnRequirement$reqGroup') {
      return (<SectionItem key={ props.questionItem.UUID } { ...props } />)
  } else {
      return (<QuestionItem key={ props.questionItem.UUID } { ...props } />)
  }

};
