import { env } from '../env';

export const getToken = () => {
	return localStorage.getItem('TOKEN')
}

export const ConfigNaumen = {
	host: env.REACT_APP_NAUMEN_HOST,
	globalKey: env.REACT_APP_NAUMEN_KEY,
};
