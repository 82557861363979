import React, { useEffect } from 'react';
import { SettingTypeSlider } from './SettingTypeSlider';
import { SettingTypeMultiple } from './SettingTypeMultiple';

export const TypeForSetting = (props) => {

	useEffect(() => {

		props.setAreaRightContent('');

		switch(props.name) {
			case 'slider':
				props.setAreaRightContent(<SettingTypeSlider { ...props } />)
				break;
			case 'multiple':
				props.setAreaRightContent(<SettingTypeMultiple { ...props } />)
				break;
			default:

			break;
		}

		props.setQuizQuestions([])

	}, [props.name, props.groupMultiple, props.setAreaRightContent, props.getAreaRightContent])

	return true

};
