import React, { useState } from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { updateQuestion } from '../Queries';

const Text = (props) => {


	const Save = (e) => {

		const answer = e.target.value

		// сохраняем answer
		// только если рабочая версия

		if (props.work) {
			updateQuestion(props.item.UUID, {'answer': answer}).then((item) => {
				if (item.data.UUID !== null && item.data.UUID !== undefined) {
					props.setSaveResult(true)
				}
			}).catch(() => {
				props.setSaveResult(false)
			})
		}

	}

    return (
        <>
         
	         <div className="QuizContent__title">
	         	{ props.item.title }
	         </div>
	         <div className="QuizContent__body">
		         <TextareaAutosize className="form-control" onBlur={ Save } value={ props.item.answer } />
	         </div>
	         
        </>
      );
  
};

export default Text