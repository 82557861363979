import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { changeRequired } from './ControlTypeQuery';

export const TypeNumber = ({ 
		questionItem, 
		getSettingsType, 
		setAreaRightContent, 
		setIsAreaRightActive,
		setQuizQuestions 
	}) => {

	const [
		isCheckedRequired, 
		setCheckedRequired
	] = useState(questionItem.required);

	return (
		<div className="flex acenter">
		    <FormControlLabel
	          value="1"
	          control={<Checkbox checked={isCheckedRequired} onChange={() => changeRequired({
	          	questionUUID: questionItem.UUID, 
	          	setCheckedRequired,
	          	isCheckedRequired,
	          	setQuizQuestions
	          })} name="required" />}
	          label="Required"
	        />

	        

		</div>
	);

};
