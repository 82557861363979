import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete, MdDragIndicator } from "react-icons/md";
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { 
  getTeams, 
  getUsers, 
  getObjects, 
  getObjectGroups, 
  createObjectGroup, 
  createObject, 
  createTeam, 
  updateTeam, 
  deleteObject 
} from '../ObjectQuery';

function PaperComponent(props) {
  return (
      <Paper {...props} />
  );
}

const MoreObject = (props) => {

  return (
  	<div key={props.index ?? '1'}>

      <Typography gutterBottom variant="h5" component="div">
        Object
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {props.item.title}
      </Typography>

      <div className="hr mx--3"></div>

      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                Description
              </InputLabel>
            </div>
            <div>
              {props.item.description ?? 'Not specified'}
            </div>
        </Grid>
      </Grid>
      <div className="mt-2"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                Responsible
              </InputLabel>
            </div>
            <div>
              {props.item.responsible ? props.item.responsible.title : 'All users'}
            </div>
        </Grid>
      </Grid>
      <div className="mt-2"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                Locations
              </InputLabel>
            </div>
            <div>
              {props.item.adress ?? 'Not specified'}
            </div>
        </Grid>
      </Grid>

      
      <div className="hr mx--3"></div>

      <Stack spacing={2} direction="row">
        <Button className="btn" variant="contained" onClick={() => props.handlerToggleFormAddObject(props.item)}>Edit object</Button>
        <Button className="btn" variant="outlined" onClick={props.handlerObjectMoreClose}>Cancel</Button>
      </Stack>

      
    </div>

  );
};

export default MoreObject