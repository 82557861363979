import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import "./Accordion.css";
import { IoIosArrowForward, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdDragIndicator, MdDelete } from "react-icons/md";
import TextareaAutosize from '@mui/material/TextareaAutosize';

const Accordion = ({ 
  type, 
  pageItem,
  provided,
  questionItem,
  header, 
  content, 
  title, 
  className, 
  PageShowQuestion, 
  showQuestion, 
  setShowQuestion,
  updatePageTitle,
  updateQuestionTitle,
  updateSectionTitle,
  deleteItem
}) => {

  useEffect(() => {
    if(type === 'section') {
      setIsActive(1)
    }
  }, [type])

  const [isActive, setIsActive] = useState(false);

  const random = Math.floor(Math.random() * (99999999 - 11111 + 1) + 5)

  if (type === 'question') {
    const clickQuestion = (e) => {

      const dots = document.querySelectorAll('.accordion-Question.active');
      dots.forEach(dot => dot.classList.remove('active'));

      const dotsActive = document.querySelectorAll('.accordion-Question-'+random);
      dotsActive.forEach(dot => dot.classList.add('active'));

    }
    
    if (questionItem.isActive === true) {
      className += ' active accordion-Question-'+random
    } else {
      className += ' accordion-Question-'+random
    }

    return (
       <div key={random} className={'accordion-item accordion-Question '+className} onClick={clickQuestion}>
       <div className="accordion-title">
       <div className="accordion-trash">
         <Fab size="small" className="accordion-toggle-section" onClick={(e) => {deleteItem(e, questionItem.UUID)}}>
          <MdDelete />
         </Fab>
       </div>

       <div className="flex acenter jbetween w-100">
          <div className="flex acenter jbetween w-100">
          <div className="fw-5">
          <Fab size="small" className="accordion-toggle-section" aria-label="accordion" {...provided.dragHandleProps}>
            {isActive ? 
              <MdDragIndicator />
            : 
              <MdDragIndicator />
            }
          </Fab>
          </div>
          <div className="fw-60">
          <div className="ChecklistItemTitle ChecklistItemTitleI w-100">
            <div contentEditable="true" 
            suppressContentEditableWarning={true} 
                name="title" 
                className={'inputQuestion w-100 inputQuestion-'+random}
                onBlur={ updateQuestionTitle } 
                spellCheck="false"
            >{title}</div>
          </div>
          </div>
          <div className="fw-35">
          {header}
          </div>
          </div>
       </div>

       </div>
       <div className="accordion-content accordion-content-body">{content}</div>
       </div>
    );
  }

  if (type === 'section') {

    

    const random = Math.floor(Math.random() * (99999999 - 11111 + 1) + 5)
    const clickSection = (e) => {

      setIsActive(!isActive)
      
    }

    {isActive ? 
      className += ' active accordion-Section-'+random
    : 
      className += ' accordion-Section-'+random
    }

    return (
       <div key={random} className={'accordion-item accordion-Section '+className}>
       <div className="accordion-title">
       <div className="accordion-trash">
         <Fab size="small" className="accordion-toggle-section" onClick={(e) => {deleteItem(e, questionItem.UUID)}}>
          <MdDelete />
         </Fab>
       </div>
       <div className="flex acenter jbetween w-100">
          
          <div className="flex acenter jbetween w-100">
          <div className="fw-10">
          <div className="flex acenter">
          <Fab size="small" className="accordion-toggle-section" aria-label="accordion" {...provided.dragHandleProps}>
              <MdDragIndicator />
          </Fab>
          <Fab size="small" className="accordion-toggle" aria-label="accordion" onClick={clickSection}>
            {isActive ? 
              <IoIosArrowUp />
            : 
              <IoIosArrowDown />
            }
          </Fab>
          </div>
          </div>
          
          <div className="fw-80">
          <div className="ChecklistItemTitle ChecklistItemTitleI">

            <div contentEditable="true" 
            suppressContentEditableWarning={true} 
                name="title" 
                className={'inputQuestion w-100 inputSection-'+random}
                onBlur={ updateSectionTitle } 
                spellCheck="false"
            >{title}</div>

          </div>
          </div>
          <div className="fw-10 flex jend">
            {header}
          </div>
          </div>

          
       </div>
       </div>
        {isActive ? 
          <div className="accordion-content">
          {content}
          </div>
        : 
          ''
        }
       </div>
    );
  }


};

export default Accordion;