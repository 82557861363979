import React, {useState, useEffect} from 'react';
import './Inspections.css';
import { InspectionsItem } from './InspectionsItem';
import { FormAddInspection } from './forms/FormAddInspection';
import InspectionsBlock from './InspectionsBlock';
import { 
  getInspections, 
  getInspectionGroups, 
} from './InspectionsQuery';
import Button from '@mui/material/Button';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import {
  Redirect,
} from "react-router-dom";


export const Inspections = (props) => {

  const [ isFormAddGroup, setFormAddGroup ] = useState(false)
  const [ isFormAddInspection, setFormAddInspection ] = useState(false)
  const [ isListChoosed, setListChoosed ] = useState(false)

  useEffect(() => {
    props.setPageActive('inspections')
  })

  const handlerToggleFormAddInspection = (item) => {
  	return;
    setFormAddGroup(false)
    setFormAddInspection(!isFormAddInspection)
    props.setAreaRightContent('')

    if (item.UUID) {
      props.setAreaRightContent(<FormAddInspection { ...props } item={item} isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddInspection={setFormAddInspection} />)
    } else {
      props.setAreaRightContent(<FormAddInspection { ...props } isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddInspection={setFormAddInspection} />)
    }
    
    props.setIsAreaRightActive(true)

  }

  return (
    <div className="bodyContent100">
      <div>

        <div className="flex acenter jbetween">
          <h1>Inspections</h1>
          
        </div>

        <InspectionsBlock { ...props } parent={0} isListChoosed={isListChoosed} setListChoosed={setListChoosed} handlerToggleFormAddInspection={handlerToggleFormAddInspection}
         />

    </div>
    </div>
  );
};
