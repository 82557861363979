import axios from 'axios';
import { useQuery } from 'react-query'
import {Get, Find, naumenEdit, naumenGet, naumenGetSingle, naumenCreate, naumenDelete, naumenDestroy, Request} from '../../api/Naumen';

export const createTeam = (query = {}, attrs = '') => {
  query.metaClass = query.metaClass ?? 'team$team'
  return naumenCreate('team$team', query);
}

export const getAudits = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('mnProject$mnProject', query);
}

export const getTeams = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('team$team', query);
}

export const getUsers = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('employee$employee', query);
}

export const createAudit = (query = {}, attrs = '') => {
	query.ParentString = query.ParentString ?? 0
    return naumenCreate('mnProject$mnProject', query);
}

export const updateAudit = (UUID, query = {}, attrs = '') => {
    return naumenEdit(UUID, query);
}

export const deleteAudit = (UUID) => {
  return naumenDelete(UUID);
}


