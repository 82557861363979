import React, { useState, useEffect, useRef } from 'react';

const MultipleItem = (props) => {

	const refItem = useRef(null)

	useEffect(() => {
		if (props.variant.status === true && refItem !== null) {
			if (props.variant.status === false) {
				refItem.current.style.backgroundColor = ''
				refItem.current.style.color = ''
			}
			if (props.variant.status === true) {
				let backgroundColor = refItem.current.dataset.color
				let color = '#000000'
				if (backgroundColor === '#000000') {
					color = '#eeeeee'
				}
				refItem.current.style.backgroundColor = backgroundColor
				refItem.current.style.color = color
			}
		}

	}, [refItem])

	if (props.variant.status === true) {
    return (
        <button key={props.variant.UUID} ref={refItem} className="QuizContent__btn" data-color={props.variant.color} data-mounted={props.variant.status} >{ props.variant.title }</button>
      );
  	} else {
  		return (<></>);
  	}
  
};

export default MultipleItem