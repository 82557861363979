import { Link } from 'react-router-dom';
import { MdDelete, MdDragIndicator } from "react-icons/md";
import MoreInspection from "./more/MoreInspection";
import Swal from 'sweetalert2'
import { RiPencilFill } from "react-icons/ri";
import { 
  deleteInspection 
} from './InspectionsQuery';

import Moment from 'moment';

export const InspectionsItem = (props) => {
  
  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert Inspection!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        deleteInspection(props.item.UUID).then(res => {
          if (res === true) {
            props.setInspections([...props.inspections.slice(0, props.index), ...props.inspections.slice(props.index + 1)]);
            /*Swal.fire(
              'Deleted!',
              'Your checklist has been deleted.',
              'success'
            )*/
          } else {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Запись не существует или у вас нет прав на ее удаление'
            })

          }
        }).catch(err => {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err
          })

        })

      }
    })

  }
  
  const handlerInspectionMoreClose = () => {

    props.setAreaRightContent('')
    props.setIsAreaRightActive(false)

  }

  const handlerInspectionMore = () => {
    props.setAreaRightContent('')
    props.setAreaRightContent(<MoreInspection { ...props } handlerInspectionMoreClose={handlerInspectionMoreClose} />)
    props.setIsAreaRightActive(true)

  }

  return (
  		<div className="table-default-tr flex jbetween">
  			
            <div className="table-default-td table-default-title" onClick={handlerInspectionMore}>
                <div>
                    {props.item.creationDate ? Moment(props.item.creationDate).format('d MMM y') : ''} / {props.item.responsible === null
                      ? 
                      ''
                      : props.item.responsible.title
                    }
                </div>
                <div className="checklist-title">
                  {props.item.title}
                </div>
                <div className="checklist-descritpion">{ props.item.description ?? '' }</div>
            </div>

            <div className="table-default-td d-none" onClick={handlerInspectionMore}>
              {props.item.number ?? '0'}
            </div>

            <div className="table-default-td" onClick={handlerInspectionMore}>
            
            {props.item.creationDate ? Moment(props.item.creationDate).format('d MMM y') : ''}
            	
            </div>

            <div className="table-default-td" onClick={handlerInspectionMore}>
            
            {props.item.conductedDate ? Moment(props.item.conductedDate).format('d MMM y') : ''}

            </div>

            <div className="table-default-td">
            
            {props.item.conducted ? 
            <Link className="link-btn-small" to={`/inspection/${props.item.UUID}`}>View&nbsp;report</Link>
            : <Link className="link-btn-small" to={`/inspection/${props.item.UUID}`}>Continue</Link>}

            </div>
            
            <div className="table-default-td table-default-btns-object">
              <div className="flex jend">
                <div className="btn-delete" onClick={handleDelete}><MdDelete /></div>
              </div>
            </div>
           
      </div>
  );
};
