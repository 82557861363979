import './ChecklistSingle.css';
import React, {useState, useEffect} from 'react';
import { deleteChecklistPage } from '../ChecklistQuery';
import { PageItemMenu } from './PageItemMenu';
import Accordion from '../../../components/Accordion/Accordion';
import { SectionQuestionItems } from './SectionQuestionItems';
import MenuItem from '@mui/material/MenuItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IoIosArrowForward, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdDragIndicator, MdDelete } from "react-icons/md";
import { 
  updatePage, 
  createChecklistQuestion, 
  createChecklistSection, 
  getQuestionsForParentPage, 
  saveSortableQuestions 
} from '../ChecklistQuery';
import Quiz from '../../../components/Quiz/Quiz';
import TextareaAutosize from '@mui/material/TextareaAutosize';

export const PageItem = (props) => {

  const [pageActive, setPageActive] = useState(true);
  const [getBlockQuestions, setBlockQuestions] = useState('');
  const [showQuestion, setShowQuestion] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const updatePageTitle = (e) => {
    updatePage(props.pageItem.UUID, {title: e.target.value}).then((question) => {
        props.setQuizQuestions([])
    })
  }
  
  const createQuestion = () => {
    createChecklistQuestion({"parentPage": props.pageItem.UUID})
      .then((question) => {
        PageShowQuestion({ pageItem: props.pageItem })
        props.setQuizQuestions([])
      })
    
    ///setShowQuestion([...showQuestion, question])
  }

  const createSection = () => {
    createChecklistSection({"parentPage": props.pageItem.UUID})
      .then((question) => {
        PageShowQuestion({ pageItem: props.pageItem })
        props.setQuizQuestions([])
      })
  }

  const PageShowQuestion = ({ pageItem }) => {
    setShowQuestion([])
    getQuestionsForParentPage({"parentPage": pageItem.UUID})
      .then((questionItems, index) => {
        const questionsSort = questionItems?.sort((a, b) => a.sort - b.sort)
        setShowQuestion(questionsSort)
      })

  }
  
  const deletePage = () => {
    deleteChecklistPage(props.pageItem.UUID)
    props.pages.map((it, index) => {
      if (it.UUID === props.pageItem.UUID) {
        props.setPages([...props.pages.slice(0, index), ...props.pages.slice(index + 1)])
      }
    })
  }

  const onDragEnd = (param) => {
      const srcI = param.source.index;
      const desI = param.destination?.index;
      showQuestion.splice(desI, 0, showQuestion.splice(srcI, 1)[0])
      saveSortableQuestions(showQuestion, props.setQuizQuestions)
      
  };

  useEffect(() => {
    let isMounted = false
    if (isMounted === false) {
      const BlockQuestions = () => {
        return (<DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"droppable-"+props.pageItem.UUID}>
            {(provided_q, _) => (
              <div ref={provided_q.innerRef} {...provided_q.droppableProps} >
                {showQuestion?.map((questionItem, index) => (

                  <Draggable key={questionItem.UUID+index} draggableId={`draggabled-${questionItem.UUID+index}`} index={index} >
                    {(provided_q, snapshot) => (
                      <div 
                        key={'child_'+questionItem.UUID+index}
                        ref={provided_q.innerRef}
                        {...provided_q.draggableProps}
                         onClick={() => props.scrollToPreview(questionItem.UUID, props.pageItem.UUID)} 
                      >
                          
                          <SectionQuestionItems key={'sectionOrQuestion_'+questionItem.UUID+index} { ...props } questionItem={questionItem} provided={provided_q} />

                      </div>
                    )}
                  </Draggable>
                ))}
                {provided_q.placeholder}

              </div>
            )}
          </Droppable>
        </DragDropContext>)
      }
      setBlockQuestions(BlockQuestions)

    }

    return () => { isMounted = true }

  }, [showQuestion])

   
  if (pageActive) {

    const className = "ChecklistPageItem";

    const menuItems = [
      <MenuItem key="Question" onClick={createQuestion}>Add Question</MenuItem>,
      <MenuItem key="Section" onClick={createSection}>Add Section</MenuItem>,
      <MenuItem key="Page" onClick={deletePage}>Delete Page</MenuItem>,
    ]

    
    return (
       <div key={props.pageItem.UUID} className={'accordion-item '+className}>
       <div className="accordion-title">
       <div className="flex acenter jbetween">
          
          <div className="flex acenter jbetween w-100">
            <div className="accordion-arrow accordion-drag flex acenter jcenter" {...props.provided.dragHandleProps}>
                <MdDragIndicator />
            </div>
            <div className="accordion-arrow flex acenter jcenter" onClick={() => {setIsActive(!isActive); PageShowQuestion({ pageItem: props.pageItem });}}>
              {isActive ? 
                <IoIosArrowDown />
              : 
                <IoIosArrowForward />
              }
            </div>
             
            <div className="ChecklistItemTitle PageItemTitle flex acenter">
              <TextareaAutosize
                  placeholder="Page Name"
                  defaultValue={props.pageItem.title} 
                  name="title" 
                  className="inputPage"
                  onBlur={ updatePageTitle }
              />
            </div>

            <PageItemMenu key={menuItems.toString()} menuItems={menuItems} />
          </div>

          

       </div>
       </div>
       {isActive && <div className="Question-List"><div className="accordion-content">{getBlockQuestions}</div></div>}
       </div>
    )




  } else {
    return ('')
  }
      
};

