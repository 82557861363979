import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { updateQuestion } from '../Queries';

const DateTime = (props) => {

	const [value, setValue] = useState(props.item.answer);

	const handleChange = (newValue) => {
	    setValue(newValue);

	    // сохраняем answer
		// только если рабочая версия

		if (props.work) {
			updateQuestion(props.item.UUID, {'answer': newValue}).then((item) => {
				if (item.data.UUID !== null && item.data.UUID !== undefined) {
					props.setSaveResult(true)
				}
			}).catch(() => {
				props.setSaveResult(false)
			})
		}

	};

    return (
        <>

	         <div className="QuizContent__title">
	         	{props.item.title}
	         </div>
	         <div className="QuizContent__body">
		        <LocalizationProvider dateAdapter={AdapterDateFns}>

			        {props.item.showDate && props.item.showTime ? 
			        	<MobileDateTimePicker
				          value={value}
				          inputFormat="dd.MM.yyyy hh:mm"
				          className="form-control"
				          onChange={handleChange}
				          renderInput={(params) => <TextField {...params} />}
				        />
			        : ''}
			      
			        {props.item.showDate && !props.item.showTime ? 
			        	<MobileDatePicker
				          inputFormat="dd.MM.yyyy"
				          className="form-control"
				          value={value}
				          onChange={handleChange}
				          renderInput={(params) => <TextField {...params} />}
				        />
			        : ''}
			      
			        {!props.item.showDate && props.item.showTime ? 
			        	<MobileTimePicker
				          className="form-control"
				          inputFormat="hh:mm"
				          value={value}
				          onChange={handleChange}
				          renderInput={(params) => <TextField {...params} />}
				        />
			        : ''}
			      
			    </LocalizationProvider>
	         </div>
	         
        </>
      );
  
};

export default DateTime