import React, {useState, useEffect} from 'react';
import './Inspections.css';
import { InspectionsItem } from './InspectionsItem';
import { FormAddInspection } from './forms/FormAddInspection';
import { 
  getInspections, 
} from './InspectionsQuery';
import Button from '@mui/material/Button';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import {
  Redirect,
} from "react-router-dom";


const InspectionsBlock = (props) => {

  const [ inspections, setInspections ] = useState([]);
  const [ inspectionGroups, setInspectionGroups ] = useState([]);

  const [ isLoading, setLoading ] = useState(true);
  const [ isError, setIsError ] = useState(false);
  const [ isSortableNumeric, setSortableNumeric ] = useState(false);
  const [ isSortableTitle, setSortableTitle ] = useState(false);
  const [ isSortableConducted, setSortableConducted ] = useState(false);
  const [ isSortableCompleted, setSortableCompleted ] = useState(false);
  const [ error, setError ] = useState('');

  const [ redirect, setRedirect ] = useState(false);

  useEffect(() => {
        getInspections({ responsible: localStorage.getItem('UUID') }).then(items => {
          
            let inspectionsSort = items?.sort((a, b) => -1)
            inspectionsSort?.map((it, index) => {it.index = index; return it})
            setInspections(inspectionsSort)
            setLoading(false)

        }).catch(err => {
          setIsError(true)
          setError(err)
        })
  }, [props.isListChoosed]) 

  if (isLoading) return <h1>...</h1>;

  if (isError) return <h1>{error.message}</h1>;

  
  

  const sortableConducted = () => {
    let inspectionGroupsNew = [...inspectionGroups]
    let inspectionsNew = [...inspections]
    if (!isSortableConducted) {
      inspectionGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
      inspectionsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
    } else {
      inspectionGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
      inspectionsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
    } 
    setSortableCompleted(false);
    setSortableConducted(!isSortableConducted);
    setSortableTitle(false);
    setSortableNumeric(false);
    setInspectionGroups(inspectionGroupsNew);
    setInspections(inspectionsNew);
  }


  const sortableCompleted = () => {
    let inspectionGroupsNew = [...inspectionGroups]
    let inspectionsNew = [...inspections]
    if (!isSortableCompleted) {
      inspectionGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.completedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.completedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
      inspectionsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.completedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.completedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
    } else {
      inspectionGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.completedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.completedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
      inspectionsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.completedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.completedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
    } 
    setSortableCompleted(!isSortableCompleted);
    setSortableConducted(false);
    setSortableTitle(false);
    setSortableNumeric(false);
    setInspectionGroups(inspectionGroupsNew);
    setInspections(inspectionsNew);
  }

  const sortableTitle = () => {
    
    let inspectionGroupsNew = [...inspectionGroups]
    let inspectionsNew = [...inspections]
    if (!isSortableTitle) {
      inspectionsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => a.title.localeCompare(b.title));
      inspectionGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => a.title.localeCompare(b.title));
    } else {
      inspectionsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => b.title.localeCompare(a.title));
      inspectionGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => b.title.localeCompare(a.title));
    }
    setSortableTitle(!isSortableTitle)
    setSortableNumeric(false);
    setSortableConducted(false);
    setSortableCompleted(false);
    setInspectionGroups(inspectionGroupsNew);
    setInspections(inspectionsNew);
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }


  return (
    
      <div>

        {inspectionGroups.length > 0 || inspections.length > 0 ? 
        <div className="table-default-wrap">
        <div className="table-default table-tr-pointer" cellSpacing="0" cellPadding="0">
          <div className="table-default-thead">
            <div className="table-default-tr flex acenter jbetween">
              <div className="table-default-th table-default-title flex acenter" onClick={sortableTitle}>Inspection {isSortableTitle ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th d-none">Score</div>
              <div className="table-default-th flex acenter" onClick={sortableConducted}>Conducted {isSortableConducted ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th flex acenter" onClick={sortableCompleted}>Completed {isSortableCompleted ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th"></div>
              <div className="table-default-th table-default-btns-object"></div>
            </div>
          </div>
          <div className="table-default-tbody">

              {inspections?.map((inspectionItem, index) => (

                  <InspectionsItem 
                    key={inspectionItem.UUID} 
                    index={index}
                    item={inspectionItem} 
                    setRedirect={setRedirect} 
                    setLoading={setLoading} 
                    setInspections={setInspections} 
                    inspections={inspections} 
                    setAreaRightContent={props.setAreaRightContent} 
                    setIsAreaRightActive={props.setIsAreaRightActive} 
                    handlerToggleFormAddInspection={props.handlerToggleFormAddInspection}
                  />
  
              ))}

          </div>
        </div>
        </div>

        : <div className="AlertInfo">Not found inspections</div>
      }

    </div>
  );
};

export default InspectionsBlock;