import React, { useState, useEffect, useRef } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import ItemsConducted from "../ItemsConducted";

const ItemSectionConducted = (props) => {

	const [isActive, setActive] = useState(false)
	const blockRef = useRef(null);

	const changeActive = () => {
		setActive(!isActive)
	}

	const query = {
		parent: props.item.UUID
	}

	useEffect(() => {
		props.refReview[props.item.UUID] = blockRef
	})

    return (
        <>
	        <div data-key={props.item.UUID} ref={blockRef}>
		        <div className={isActive ? 'QuizContentSection active' : 'QuizContentSection'} >
		        	<div className="QuizContentSection-btn flex acenter jbetween" onClick={changeActive} >
		        		<div className="flex acenter">
			        	<div className="flex acenter mr-1">
				        	{isActive ? 
				              <IoIosArrowUp />
				            : 
				              <IoIosArrowDown />
				            }
				        </div>
			        	<div>{props.item.title}</div>
			        	</div>
			        	<div>
			        		
			        	</div>
            		</div>

            		{isActive ? <ItemsConducted { ...props } pageItem={null} query={query} /> : ''}

		        </div>
		    </div>
        </>
    );
  
};

export default ItemSectionConducted