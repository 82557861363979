import React, {useState} from 'react';
import { updateQuestion } from './ControlTypeQuery';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { TypeForCard } from './ControlTypeForCard'

function ValueLabelComponent(props) {
	const { children, value } = props;

	return (
	    <Tooltip enterTouchDelay={0} placement="top" arrow title={value}>
	      {children}
	    </Tooltip>
	);
}

export const SettingTypeSlider = (props) => {

	let [inputMin, setInputMin] = useState(props.questionItem.min ?? 1);
	let [inputMax, setInputMax] = useState(props.questionItem.max ?? 10);
	let [inputSlideInc, setInputSlideInc] = useState(props.questionItem.slideIncrement ?? 1);

	const inputHandler = (e) => {
		var val = parseInt(e.target.value) ?? 1
		var name = e.target.name
		if (name === 'min') {
			setInputMin(val)
			inputMin = val
		} else if (name === 'max') {
			setInputMax(val)
			inputMax = val
		} else if (name === 'slideIncrement') {
			setInputSlideInc(val)
			inputSlideInc = val
		}

		if (inputMin > inputMax) {
			setInputMin(inputMax)
			e.target.value = inputMax
		}
		if (inputSlideInc > inputMax) {
			setInputSlideInc(inputMax)
			e.target.value = inputMax
		}
		
	}

	const saveHandler = (e) => {
		e.preventDefault()

		const query = {
			min: inputMin,
			max: inputMax,
			slideIncrement: inputSlideInc
		}
		props.setTypeSetting('')
		updateQuestion({questionUUID: props.questionItem.UUID, query}).then((item) => {

			const AccordionContent = <TypeForCard 
		        name='slider' 
		        { ...props } 
		        questionItem={item.data} 
		        />
			props.setTypeSetting(AccordionContent)

			props.setAreaRightContent('')
			props.setIsAreaRightActive(false)
			props.setQuizQuestions([])
			const showQuestionNew = props.showQuestion.map((question) => {
		        if (question.UUID === props.questionItem.UUID) {
		          return item
		        } else {
		        	return question
		        }
      		})

      		props.setQuizQuestions([])

		})
		
	}

	const cancelHandler = (e) => {
		e.preventDefault()
		props.setAreaRightContent('')
		props.setIsAreaRightActive(false)
	}

	const defaultValue = Math.ceil(inputMax / 2);

	return (
		<div>

			<Typography gutterBottom variant="h5" component="div">
	            Slider
	        </Typography>
	        <Typography variant="body2" color="text.secondary">
	            You can define the range of response with the slider.
	        </Typography>

	        <div className="hr mx--3"></div>

	        <Typography variant="body2" color="text.secondary">
	            Preview
	        </Typography>

			<Slider key={`slider-edit-${props.questionItem.UUID}`} 
					defaultValue={defaultValue} 
					valueLabelDisplay="auto" 
					components={{
		               ValueLabel: ValueLabelComponent,
		            }} 
		            step={inputSlideInc} 
		            min={inputMin} 
		            max={inputMax} 
		            className="pt-4 mb--2" 
		    />

            <Grid container spacing={0}>
			  <Grid item xs={6}>{inputMin}</Grid>
			  <Grid item xs={6} sx={{ textAlign: 'right' }}>{inputMax}</Grid>
			</Grid>
			<div className="hr mx--3"></div>

		    <Grid container spacing={6}>
			  <Grid item xs={6}>
			    	<div className="pt-2">
					    <InputLabel shrink htmlFor="bootstrap-input">
			          		Min
			        	</InputLabel>
						<div>
							<Input
							  type="number"
							  className="form-control"
					          id="outlined-required"
					          label="Min" 
					          defaultValue={inputMin} 
					          name="min" 
					          onChange={inputHandler}
					        />
				        </div>
			        </div>
			  </Grid>
			  <Grid item xs={6}>
			    	<div className="pt-2">
					    <InputLabel shrink htmlFor="bootstrap-input">
			          		Max
			        	</InputLabel>
						<div>
							<Input
							  type="number"
							  className="form-control"
					          id="outlined-required"
					          label="Max" 
					          defaultValue={inputMax} 
					          name="max" 
					          onChange={inputHandler}
					        />
				        </div>
			        </div>
			  </Grid>
			</Grid>

			<Grid container sx={{ paddingTop: '20px' }} spacing={6}>
			  <Grid item xs={6}>
			    	<div>
					    <InputLabel shrink htmlFor="bootstrap-input">
			          		Increment
			        	</InputLabel>
						<div>
							<Input
							  type="number"
							  className="form-control"
					          id="outlined-required"
					          label="Increment" 
					          defaultValue={inputSlideInc} 
					          name="slideIncrement" 
					          onChange={inputHandler}
					        />
				        </div>
			        </div>
			  </Grid>
			</Grid>

			<div className="hr mx--3"></div>

			<Stack spacing={2} direction="row">
		      <Button className="btn" variant="contained" onClick={saveHandler}>Save and apply</Button>
		      <Button className="btn" variant="outlined" onClick={cancelHandler}>Cancel</Button>
		    </Stack>


		</div>
	);

};
