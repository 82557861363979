import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Header.module.css";
import logo from '../../assets/images/logo.png'
import { isInApp } from '../../api/Naumen';
import { GiHamburgerMenu } from 'react-icons/gi';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

export const Header = ({ isLoggedIn, setIsLoggedIn, userFirstName, userLastName, setIsAdmin, setMenuActive, isMenuActive }) => {

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const toggleMenu = () => {
    setMenuActive(!isMenuActive)
  };

  const handleLogOut = () => {
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('isLoggedIn') 
    localStorage.removeItem('userFirstName')
    localStorage.removeItem('userLastName')
    setIsLoggedIn(false);
    setIsAdmin(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>

      {
      
        isLoggedIn ?
        <header className={styles.mainHeader}>

        <div className="flex acenter jbetween">
        <div className="flex acenter">
          <NavLink to="/"><img src={logo} className={styles.logo} alt="" /></NavLink>
          <div className="burgerMenu" onClick={toggleMenu}><GiHamburgerMenu /></div>
        </div>
        <nav>

          {!isInApp() ? 

            <Box sx={{ flexGrow: 0 }} className="mr-4">
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                
                <MenuItem key="logout" onClick={handleLogOut}>
                    <NavLink
                      onClick={handleLogOut}
                      exact
                      to="/login"
                    >
                  <Typography textAlign="center">LogOut</Typography></NavLink>
                
                </MenuItem>

            </Menu>
          </Box>

           
            : ''}
          
        </nav>
        </div>
        </header>
        : 

        <header className={styles.mainHeader}>

        <div className="flex acenter jbetween">
        <NavLink to="/"><img src={logo} className={styles.logo} alt="" /></NavLink>
        <nav>

        </nav>
        </div>
        </header>
      
      }

    </>
  );
};
