import axios from 'axios';
import { useQuery } from 'react-query'
import {Get, Find, naumenEdit, naumenGet, naumenGetSingle, naumenCreate, naumenDelete, naumenDestroy, Request} from '../../api/Naumen';

export const getObjectGroups = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('nmObject$objGroup', query);
}

export const createObjectGroup = (query = {}, attrs = '') => {
  query.ParentString = query.ParentString ?? 0
  return naumenCreate('nmObject$objGroup', query);
}

export const createTeam = (query = {}, attrs = '') => {
  query.metaClass = query.metaClass ?? 'team$team'
  return naumenCreate('team$team', query);
}

export const updateTeam = (query = {}, attrs = '') => {
  query.members = query.members ?? ['employee$5429601']
  return naumenEdit('team$6017101', query);
}

export const getObjects = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('nmObject$mnObject', query);
}

export const getTeams = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('team$team', query);
}

export const getUsers = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('employee$employee', query);
}

export const createObject = (query = {}, attrs = '') => {
	query.ParentString = query.ParentString ?? 0
    return naumenCreate('nmObject$mnObject', query);
}

export const updateObject = (UUID, query = {}, attrs = '') => {
    return naumenEdit(UUID, query);
}

export const deleteObjectGroup = (UUID) => {
  return naumenDelete(UUID);
}

export const deleteObject = (UUID) => {
  return naumenDelete(UUID);
}


