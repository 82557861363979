import './ChecklistSingle.css';
import React, {useState, useEffect} from 'react';
import { SectionQuestionItems } from './SectionQuestionItems';
import { PageItemMenu } from './PageItemMenu';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '../../../components/Accordion/Accordion';
import { 
  updateSection, 
  getQuestionsForParentPage, 
  createChecklistQuestion, 
  createChecklistSection, 
  deleteQuestion, 
  saveSortableQuestions 
} from '../ChecklistQuery';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const SectionItem = (props) => {
  
  const [getSections, setSections] = useState([]);
  const [getContentShowQuestion, setContentShowQuestion] = useState('');
  const [isContentShowQuestion, setIsContentShowQuestion] = useState(false);

  const onDragEnd = (param, questionsSort) => {
      const srcI = param.source.index;
      const desI = param.destination?.index;
      questionsSort.splice(desI, 0, questionsSort.splice(srcI, 1)[0])
      setSections(questionsSort)
      saveSortableQuestions(questionsSort, props.setQuizQuestions)
  };

  useEffect(() => {
    
    let isMounted = false
    if (isMounted === false) {
        getQuestionsForParentPage({"parent": props.questionItem.UUID})
      .then((questionItems, index) => {
        
        if (questionItems.length > 0) {
          console.log('SectionItem')
          const questionsSort = questionItems?.sort((a, b) => a.sort - b.sort)
          setSections(questionsSort)
        }
         })
    }

      return () => {isMounted = true}

  }, [])

  useEffect(() => {
    
    let isMounted = false
    if (isMounted === false) {

          const ContentShowQuestion = () => {
          return (
            <DragDropContext onDragEnd={(param)=>onDragEnd(param, getSections)}>
                    <Droppable droppableId={"droppable-"+props.questionItem.UUID}>
                      {(provided_s, _) => (
                        <div ref={provided_s.innerRef} {...provided_s.droppableProps} >

                        {getSections?.map((questionItemData, index) => {

                          return (<Draggable key={questionItemData.UUID+index} draggableId={`draggable_item-${questionItemData.UUID+index}`} index={index}>
                              {(provided_s, snapshot) => (
                                <div 
                                  key={'child_'+questionItemData.UUID+index}
                                  ref={provided_s.innerRef}
                                  {...provided_s.draggableProps}
                                >


                                  <SectionQuestionItems key={'sectionOrQuestion_'+questionItemData.UUID+index} { ...props } questionItem={questionItemData} provided={provided_s} />


                                </div>
                              )}
                            </Draggable>)
                        })}
                        {provided_s.placeholder}
                        </div>
                        )}

                    </Droppable>
                  </DragDropContext>
          )
      }

      setContentShowQuestion(ContentShowQuestion)
      
      }

      return () => {isMounted = true}

  }, [getSections])


  const deleteItem = (e, UUID) => {
    deleteQuestion(UUID)
    const getSectionsNew = getSections.map((item) => {
      if (item.UUID !== UUID) {
        return item
      }
    })
    setSections(getSectionsNew)
    //e.target.closest('.accordion-item').style.display = 'none'
  }

  const createQuestion = () => {
    createChecklistQuestion({"parent": props.questionItem.UUID})
      .then((questionItemCh) => {

          setSections([...getSections, questionItemCh])

      })
  }

  const createSection = () => {
    createChecklistSection({"parent": props.questionItem.UUID})
      .then((questionItemCh) => {

        setSections([...getSections, questionItemCh])

      })
  }

  const updateSectionTitle = (e) => {

    const text = e.target.innerHTML.replace(/(<([^>]+)>)/ig, '');
    updateSection(props.questionItem.UUID, {title: text}).then(() => {
      //ReloadItemSection(props)
    })
  }

  const menuItems = [
      <MenuItem key="Question" onClick={createQuestion}>Add Question</MenuItem>,
      <MenuItem key="Section" onClick={createSection}>Add Section</MenuItem>,
  ]

  const AccordionHeader = () => {
      return (
        <PageItemMenu menuItems={menuItems} />
      )
  }

  return (<Accordion type="section" key={props.questionItem.UUID} provided={props.provided} updateSectionTitle={updateSectionTitle} header=<AccordionHeader /> content={getContentShowQuestion} title={props.questionItem.title} className={"ChecklistItem item"} questionItem={props.questionItem} deleteItem={deleteItem} />)
      
};
