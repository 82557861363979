import React, {useState, useEffect} from 'react';
import './Objects.css';
import { ObjectItem } from './ObjectItem';
import { ObjectGroupItem } from './ObjectGroupItem';
import { FormAddGroup } from './forms/FormAddGroup';
import { FormAddObject } from './forms/FormAddObject';
import ObjectsBlock from './ObjectsBlock';
import { 
  getObjects, 
  getObjectGroups, 
} from './ObjectQuery';
import Button from '@mui/material/Button';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import {
  Redirect,
} from "react-router-dom";


export const Objects = (props) => {

  const [ isFormAddGroup, setFormAddGroup ] = useState(false)
  const [ isFormAddObject, setFormAddObject ] = useState(false)
  const [ isListChoosed, setListChoosed ] = useState(false)

  const handlerToggleFormAddGroup = (item) => {
    setFormAddObject(false)
    setFormAddGroup(!isFormAddGroup)

    props.setAreaRightContent('')
    if (item.UUID) {
      props.setAreaRightContent(<FormAddGroup { ...props } item={item} isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddGroup={setFormAddGroup} />)
    } else {
      props.setAreaRightContent(<FormAddGroup { ...props } isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddGroup={setFormAddGroup} />)
    }
    props.setIsAreaRightActive(true)

  }

  const handlerToggleFormAddObject = (item) => {
    setFormAddGroup(false)
    setFormAddObject(!isFormAddObject)
    props.setAreaRightContent('')

    if (item.UUID) {
      props.setAreaRightContent(<FormAddObject { ...props } item={item} isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddObject={setFormAddObject} />)
    } else {
      props.setAreaRightContent(<FormAddObject { ...props } isListChoosed={isListChoosed} setListChoosed={setListChoosed} setFormAddObject={setFormAddObject} />)
    }
    
    props.setIsAreaRightActive(true)

  }

  return (
    <div className="bodyContent100">
      <div>

        <div className="flex acenter jbetween">
          <h1>Objects</h1>
          <div>
          <Button className="btn mr-2" variant="contained" onClick={handlerToggleFormAddGroup}>+ Add Group</Button>
          <Button className="btn" variant="contained" onClick={handlerToggleFormAddObject}>+ Add Object</Button>
          </div>
        </div>

        <ObjectsBlock { ...props } parent={0} isListChoosed={isListChoosed} setListChoosed={setListChoosed} handlerToggleFormAddObject={handlerToggleFormAddObject} handlerToggleFormAddGroup={handlerToggleFormAddGroup} />

    </div>
    </div>
  );
};
