import axios from "axios";
import { useQuery } from 'react-query'
import {ConfigNaumen, getToken} from '../config/Naumen';
import moment from 'moment';

export const isInApp = () => {
  if ( window.parent.injectJsApi === undefined ) { 
    return false
  } else { return true }
}

export function setTimeActive() {
  localStorage.setItem("timeActive", Date.parse(Date()))
}

export function resetTimeActive() {

  if (isInApp() === true) {
     // Если это внутреннее приложение
     // каждые 2 минут обновляем ключ
     setInterval(function() {
      const login = localStorage.getItem("userLogin")
        AuthToken(login).then(token => {
          localStorage.setItem('TOKEN', token);
        })
     }, 2*60000)
  }

  if (isInApp() === false) {
    
    setInterval(function() {
      const timeActive = localStorage.getItem("timeActive")
      if (timeActive) {
        HasAuthToken()
      }
    }, 60000)

    setInterval(function() {
      // Проверяем время активности
      const timeActive = localStorage.getItem("timeActive")
      if (timeActive) {
        const intervalTimeActive = Date.parse(Date()) - timeActive
        if (intervalTimeActive > (15*60000)) {
          // Очищаем сессию
          localStorage.removeItem('TOKEN')
          localStorage.removeItem('isLoggedIn')
          localStorage.removeItem('userFirstName')
          localStorage.removeItem('userLastName')
          localStorage.removeItem('timeActive')
          window.location.reload()
        }
      }
    }, 2000)

  }
    
}

export async function AuthToken(login) {
  login = login.replace('.', '__dot__')
  try {
    let queryData = RequestGlobal({ method: 'exec', query: {
      "func":"modules.mnMobitMain.getAccesKey"
      },
      addon: '&params="'+login+'"'
    });
    //addon: '&params="admaxotek","Herbghjxm1"'
    /*queryData.headers = {
         'Access-Control-Allow-Origin': '*'
       }*/
    const {data:response} = await axios(queryData)

    return response
  }
  catch (error) {
    HasAuthToken()
    return 'error'
  }
}

export async function HasAuthToken() {

    StartHasAuthToken(getToken()).then((live) => {
      
      if (live === false) {
        // Если время жизни ключа истекло, удаляем сессию
        localStorage.removeItem('TOKEN')
        localStorage.removeItem('isLoggedIn')
        localStorage.removeItem('userFirstName')
        localStorage.removeItem('userLastName')
        localStorage.removeItem('timeActive')
        window.location.reload()
      } else {
        const deadline = moment(live.deadline).valueOf(); 
        const now = moment().valueOf(); 
        const time_after = now - deadline; 
        
        if (time_after >= 0) {
          // Если время жизни ключа истекло, удаляем сессию
          localStorage.removeItem('TOKEN')
          localStorage.removeItem('isLoggedIn')
          localStorage.removeItem('userFirstName')
          localStorage.removeItem('userLastName')
          localStorage.removeItem('timeActive')
          window.location.reload()
        }
      }
    })
}

export async function StartHasAuthToken(token) {

  try {

    let queryData = RequestGlobal({ method: 'exec', query: {
      "func":"modules.mnMobitMain.hasAccessKey"
      },
      addon: '&params="'+token+'"'
    });
    //addon: '&params="admaxotek","Herbghjxm1"'
    /*queryData.headers = {
         'Access-Control-Allow-Origin': '*'
       }*/
    const {data:response} = await axios(queryData)

    return response
  }
  catch (error) {
    HasAuthToken()
    return 'error'
  }
}

export async function AuthAD(login, password) {
  try {
    //func=modules.mnMobitMain.LDAPauth&params=%22admaxotek%22,%22Herbghjxm11%22
    let queryData = RequestGlobal({ method: 'exec', query: {
      "func":"modules.mnMobitMain.LDAPauth"
      },
      addon: '&params="'+login+'","'+password+'"'
    });
    /*queryData.headers = {
         'Access-Control-Allow-Origin': '*'
       }*/
    //addon: '&params="admaxotek","Herbghjxm1"'
    const {data:response} = await axios(queryData)

    return response
  }
  catch (error) {
    HasAuthToken()
    return 'error'
  }
}

export const Exec = () => {

  var query = {
      "func":"modules.mnMobitMain.mnMultipleRespColor"
  }

  const addon = '&params=nmGroupMultipl$18601';

  if (query) {
    query = '&'+(new URLSearchParams(query).toString());
  }
  const url = ConfigNaumen.host+'exec/?accessKey='+getToken()+'&func=modules.mnMobitMain.mnMultipleRespColor&params=nmGroupMultipl$18601,nmGroupMultipl$18602';
  
  const queryData = {
    method: 'post',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
    url: url,
  }

  return useQuery('Exec', () => {
    return axios(queryData)
      .then(res => {console.log(res)})
      .catch(err => {
        HasAuthToken()
        throw new Error(err)
      })
  }, {
    refetchOnWindowFocus: false,
  })

}

export const Get = ({ key, UUID }) => {

  const queryData = Request({ method: 'get', metaClass: UUID });

  return useQuery(key, () => {
    return axios(queryData)
      .then(res => res.data)
      .catch(err => {
        HasAuthToken()
        throw new Error(err)
      })
  }, {
    refetchOnWindowFocus: false,
  })

}

export const Find = ({ key, metaClass, query = {}, attrs = '' }) => {
  query = {removed:0, ...query};
  const queryData = Request({ method: 'find', metaClass, query, attrs });

  return useQuery(key, () => {
    return axios(queryData)
      .then(res => res.data)
      .catch(err => {
        HasAuthToken()
        throw new Error(err)
      })
  }, {
    refetchOnWindowFocus: false,
  })

}

export const Edit = ({ key, UUID, query = '', attrs = '' }) => {
  
  const queryData = Request({ method: 'edit-m2m', metaClass: UUID, query, attrs });

  return useQuery(key, () => {
    return axios(queryData)
      .then(res => res.data)
      .catch(err => {
        HasAuthToken()
        throw new Error(err)
      })
  }, {
    refetchOnWindowFocus: false,
  })

}

export async function naumenGet(metaClass, query) {
  try {
    const {data:response} = await axios(Request({ method: 'find', metaClass, query })) //use data destructuring to get data from the promise object
    return response
  }
  catch (error) {
    HasAuthToken()
    
    return error
  }
}

export async function naumenGetSingle(UUID) {
  try {
    const {data:response} = await axios(Request({ method: 'get', metaClass: UUID })) //use data destructuring to get data from the promise object
    return response
  }
  catch (error) {
    HasAuthToken()
    return error
  }
}

export async function naumenGetGlobal(metaClass, query) {
  try {
    const {data:response} = await axios(RequestGlobal({ method: 'find', metaClass, query })) //use data destructuring to get data from the promise object
    return response
  }
  catch (error) {
    HasAuthToken()
    return error
  }
}

export async function naumenEdit(UUID, query, attrs = '') {
  return axios(Request({ method: 'edit-m2m', metaClass: UUID, query }))
  
}

export async function naumenCreate(metaClass, query, attrs = '') {
  try {
    const {data:response} = await axios(Request({ method: 'create-m2m', metaClass, query })) //use data destructuring to get data from the promise object
    return response
  }
  catch (error) {
    HasAuthToken()
    return error
  }
}

export async function naumenDelete(UUID) {
  const query = {removed: 1}
  try {
    const {status} = await axios(Request({ method: 'edit-m2m', metaClass: UUID, query }))
    return status === 200 ?  true : false
  }
  catch (error) {
    HasAuthToken()
    return false
  }
}

export async function naumenDestroy(UUID) {
  try {
    const {status} = await axios(Request({ method: 'delete', metaClass: UUID }))
    console.log(status)
    return status === 200 ?  true : false
  }
  catch (error) {
    HasAuthToken()
    return false
  }
}

export function Request({ method, metaClass = '', query = '', attrs = '', addon = '' }) {
  
  setTimeActive()

  if (attrs) {
    attrs = '&attrs='+attrs;
  }

  if (query) {
    query = '&'+(new URLSearchParams(query).toString());
  }
  if (metaClass) { metaClass = '/'+metaClass; }
  const url = ConfigNaumen.host+method+metaClass+'/?accessKey='+getToken()+query+attrs+addon;
  var queryMethod = 'post';
  if (method === 'get' || method === 'exec') {
    queryMethod = 'get';
  }
  const queryData = {
    method: queryMethod,
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    url: url,
  };
  return queryData;
}

export function RequestGlobal({ method, metaClass = '', query = '', attrs = '', addon = '' }) {

  setTimeActive()

  if (attrs) {
    attrs = '&attrs='+attrs;
  }
  if (query) {
    query = '&'+(new URLSearchParams(query).toString());
  }
  if (metaClass) { metaClass = '/'+metaClass; }
  const url = ConfigNaumen.host+method+metaClass+'/?accessKey='+ConfigNaumen.globalKey+query+attrs+addon;
  var queryMethod = 'post';
  if (method === 'get' || method === 'exec') {
    queryMethod = 'get';
  }
  const queryData = {
    method: queryMethod,
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
    url: url,
  };
  return queryData;
}










