import React, { useState, useEffect } from 'react';
import { getTypes, getMultipleGroups } from '../ChecklistQuery';
import { changeTypeForQuestion, changeTypeForQuestionMultiple, getTypeItemForName } from './types/ControlTypeQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { TypeForCard } from './types/ControlTypeForCard';
import { createMultipleGroup } from './types/ControlTypeQuery';
import { MultipleGroupsData } from './MultipleGroupsData';
import { MultipleGroupsCard } from './MultipleGroupsCard';


export const QuestionTypes = ({ questionItem, checklistItem, getSettingsType, setAreaRightContent, setIsAreaRightActive, setContainerTypesShow, setTypeTitle, setTypeSetting, quizQuestions, setQuizQuestions }) => {

	const [getAllMultipleGroups, setAllMultipleGroups] = useState('')
	const [getOtherTypes, setOtherTypes] = useState('')

	const createMultipleGroupHandler = () => {
		createMultipleGroup({cList: checklistItem.UUID})
		.then(groupMultiple => {
			getTypeItemForName('multiple').then(typeItem => {
				getSettingsType({
					setAreaRightContent, 
					setIsAreaRightActive, 
					name: 'multiple', 
					typeItem: typeItem[0], 
					questionItem, 
					setTypeTitle, 
					groupMultiple,
					setContainerTypesShow, 
					getSettingsType, 
					setTypeSetting,
					quizQuestions,
					setQuizQuestions
				});
			}) 
			setContainerTypesShow(false)
		})
		
	}

	const updateTypeForQuestionMultiple = ({ typeItem, groupMultiple }) => {

	  changeTypeForQuestionMultiple({ questionItem, typeItem, groupMultiple })
		  .then(() => {
		  	setQuizQuestions([])
		  })
	  const groupMultiples = [groupMultiple]
	  var typeTitleData = typeItem.title ?? 'Choose Type'
	  if (questionItem.groupMultiple) {
	    typeTitleData = <MultipleGroupsCard key={questionItem.UUID} setQuizQuestions={setQuizQuestions} quizQuestions={quizQuestions} multipleGroups={groupMultiples} setTypeSetting={setTypeSetting} />
	    
	  }
	  setTypeTitle(typeTitleData)
	  //setIsAreaRightActive(false)
	  setContainerTypesShow(false)
	  const AccordionContent = <TypeForCard 
	        name='multiple' 
	        questionItem={questionItem} 
	        setTypeSetting={setTypeSetting} 
	        getSettingsType={getSettingsType} 
	        setAreaRightContent={setAreaRightContent} 
	        setIsAreaRightActive={setIsAreaRightActive} 
        	groupMultiple={groupMultiple}
        	setContainerTypesShow={setContainerTypesShow} 
        	quizQuestions={quizQuestions} 
        	setQuizQuestions={setQuizQuestions} 
	        />
	  setTypeSetting(AccordionContent)
	}

	const updateTypeForQuestion = ({ typeItem }) => {
	  setTypeSetting('')
	  changeTypeForQuestion({ questionItem, typeItem })
	  	.then((item) => {
	  		const AccordionContent = <TypeForCard 
		        name={typeItem.code ?? ''} 
		        questionItem={item.data} 
		        setTypeSetting={setTypeSetting} 
		        getSettingsType={getSettingsType} 
		        setAreaRightContent={setAreaRightContent} 
		        setIsAreaRightActive={setIsAreaRightActive} 
		        setContainerTypesShow={setContainerTypesShow} 
		        quizQuestions={quizQuestions} 
		        setQuizQuestions={setQuizQuestions} 
		        />
		  	setTypeSetting(AccordionContent)
		  	setQuizQuestions([])
		})
	  setTypeTitle(typeItem.title)
	  //setIsAreaRightActive(false)
	  setContainerTypesShow(false)
	  
	}


	useEffect(() => {
		//let isAmount = false
		//if (!isAmount) {

		//const listTypes = getTypes()

		getTypes().then((types) => {

			types?.map((typeItem) => {
				if (typeItem.code === 'multiple') {

					getMultipleGroups({cList: checklistItem.UUID}).then(multipleGroupsNew => {
						if (multipleGroupsNew.length > 0) {

							var setAllMultipleGroupsT = <MultipleGroupsData multipleGroups={multipleGroupsNew} updateTypeForQuestionMultiple={updateTypeForQuestionMultiple} getSettingsType={getSettingsType} setContainerTypesShow={setContainerTypesShow} typeItemData={typeItem} setAreaRightContent={setAreaRightContent} setIsAreaRightActive={setIsAreaRightActive} questionItem={questionItem} setTypeTitle={setTypeTitle} setTypeSetting={setTypeSetting} setQuizQuestions={setQuizQuestions} quizQuestions={quizQuestions} />
							setAllMultipleGroups(setAllMultipleGroupsT)
						} 

					})
				}

			})
		})

		getTypes().then((types) => {
			const OtherTypes = types?.map((typeItem) => {
				if (typeItem.code !== 'multiple') {
					return (
						<div key={typeItem.UUID} className={'multiple-item'} onClick={ ()=> updateTypeForQuestion({ typeItem }) } >
						    {typeItem.title}
						</div>
					);
				}
			});
			setOtherTypes(OtherTypes)
		});

		//}
		//return () => { isAmount = true }

	}, [quizQuestions])

	

	return (
		<div>
		<Grid container spacing={0}>
                <Grid className="border-right-1" item xs={6}>
                  <div className="td-content">
                    <div className="flex jbetween">
                      <Typography variant="body2" className="flex jbetween" color="text.secondary">
                        Multiple choice responses
                      </Typography>
                      <div>
                          <Link className="link" onClick={createMultipleGroupHandler}>+ Responses</Link>
                      </div>
                    </div>
                  </div>
                  <div className="multiple-items">
                    {getAllMultipleGroups}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="td-content">
                    <Typography variant="body2" color="text.secondary">
                        Other responses
                    </Typography>
                  </div>
                  <div className="pb-2">
                  	{getOtherTypes}
                  </div>
                </Grid>
              </Grid>
		    
		</div>
	);

};
