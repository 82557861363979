import styles from "./Menu.module.css";
import { NavLink } from "react-router-dom";
import { CgList, CgBriefcase, CgPin } from "react-icons/cg";
import imgObject from '../../assets/images/icon/object_active.png'

export const Menu = () => {
  return (
    <>
   
   		<div className="Menu">

	   		<ul className={styles.ul}>
	   			
          <li><NavLink exact to="/checklist" className={styles.NavLink} activeClassName={styles.NavLinkActive}><div className={styles.icon}><CgList /></div><span>Checklists</span></NavLink></li>

          <li><NavLink exact to="/object" className={styles.NavLink} activeClassName={styles.NavLinkActive}><div className={styles.icon}><CgPin /></div><span>Objects</span></NavLink></li>
          
	   			<li>
            <NavLink to="/audit" className={styles.NavLink} activeClassName={styles.NavLinkActive}><div className={styles.icon}><CgBriefcase /></div><span>Audits</span></NavLink>
            <ul className={styles.MenuChild}>
            <li><NavLink to="/task" className={styles.NavLink} activeClassName={styles.NavLinkActive}><div className={styles.icon}></div><span>Tasks</span></NavLink></li>
            <li><NavLink to="/inspection" className={styles.NavLink} activeClassName={styles.NavLinkActive}><div className={styles.icon}></div><span>Inspections</span></NavLink></li>
            </ul>
          </li>

          <li><NavLink to="/project" className={styles.NavLink} activeClassName={styles.NavLinkActive}><div className={styles.icon}><CgBriefcase /></div><span>Projects</span></NavLink></li>

	   		</ul>

   		</div>
    
    </>
  );
};
