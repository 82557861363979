import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete, MdDragIndicator } from "react-icons/md";
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import ModalUsers from './ModalUsers';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { 
  getTeams, 
  getUsers, 
  getObjects, 
  getObjectGroups, 
  createObjectGroup, 
  createObject, 
  updateObject, 
  createTeam, 
  updateTeam, 
  deleteObject 
} from '../ObjectQuery';

function PaperComponent(props) {
  return (
      <Paper {...props} />
  );
}

export const FormAddObject = (props) => {

  let defaultValue = {}
  if (props.item !== undefined && props.item !== null) {
    defaultValue = {
      title: props.item.title ?? 'No name',
      description: props.item.description ?? '',
      adress: props.item.adress ?? '',
    }
  } else {
    defaultValue = {
      title: '',
      description: '',
      adress: '',
    }
  }
  const [ objectGroupsAll, setObjectGroupsAll ] = useState([]);
  const [ objectData, setObjectData ] = useState(defaultValue);
  const [ teams, setTeams ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const [checked, setChecked] = useState([1]);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [userUsers, setUserUsers] = useState([]);
  const [getResponsible, setResponsible] = useState(props.item ? props.item.responsible : {});
  const [isItemTeamSelected, setItemTeamSelected] = useState(false);

  const [selected, setSelected] = React.useState([]);
  const isSelected = (UUID) => selected.indexOf(UUID) !== -1;

  const [open, setOpen] = useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  const handleRemoveGroup = () => {

    setResponsible({});

  };

  const handleAddUserGroup = (event) => {
    event.preventDefault()
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserGroup = () => {
    setAnchorElUser(null);
  };

  const refTitle = useRef('title');

  useEffect(() => {

    let isMounted = false
        
        /*updateTeam().then(items => {
          console.log(items)
        })
        getUsers().then(items => {
          console.log(items)
        })*/

        getObjectGroups().then(items => {
          if (!isMounted) {
            
            setObjectGroupsAll(items)

          }
        }).catch(err => {
          alert(err)
        })
        
    return () => { isMounted = true }
  }, [])

  useEffect(() => {

        getTeams().then(items => {
            const teamsNew = items.filter((ita) => {

              ita.status = false
              if (getResponsible) {
                if (ita.UUID === (getResponsible.UUID ?? false)) {
                  ita.status = true
                }
              }

              return ita.members.length > 0
            })
            setTeams(teamsNew)

        }).catch(err => {
          alert(err)
        })
        
  }, [getResponsible]) 

  useEffect(() => {

        getUsers().then(items => {
            
            setUsers(items)

        }).catch(err => {
          alert(err)
        })
        
  }, []) 

  const getGroupsForParent = (parentUUID) => {
    return objectGroupsAll.map((item, index) => {
      if (item.ParentString === parentUUID) {
        return <MenuItem key={index} value={item.UUID}>- {item.title}</MenuItem>
      }
    })
  }

  const createHandler = (e) => {
    if (objectData.title !== undefined && objectData.title !== null && objectData.title !== '') {
      createObject(objectData).then(res => {
        console.log(res)
        if (res.UUID !== undefined && res.UUID !== null) {
          // Обновляем список 
          setTimeout(() => {
            props.setListChoosed([])
          }, 300)
          setObjectData({})
          props.setFormAddObject(false)

          //props.setAreaRightContent('')
          props.setIsAreaRightActive(false)

        }
      }).catch(err => {
        alert(err)
      })

    } else {
      refTitle.current.focus();
      //alert('Введите название объекта')
    }
  }
  const updateHandler = (e) => {
    if (objectData.title !== undefined && objectData.title !== null && objectData.title !== '') {
      updateObject(props.item.UUID, objectData).then(res => {
        if (res.data.UUID !== undefined && res.data.UUID !== null) {
          // Обновляем список 
          setTimeout(() => {
            props.setListChoosed([])
          }, 300)
          setObjectData({})
          props.setFormAddObject(false)

          //props.setAreaRightContent('')
          props.setIsAreaRightActive(false)

        }
      }).catch(err => {
        alert(err)
      })

    } else {
      refTitle.current.focus();
      //alert('Введите название объекта')
    }
  }

  const handlerChooseData = (e) => {
    if (e.target.name === 'title') {
      objectData.title = e.target.value
    }
    if (e.target.name === 'description') {
      objectData.description = e.target.value
    }
    if (e.target.name === 'adress') {
      objectData.adress = e.target.value
    }
    if (e.target.name === 'parent') {
      objectData.parentObj = e.target.value
      objectData.ParentString = e.target.value
    }
    setObjectData(objectData)
  }


  const cancelHandler = (e) => {
    e.preventDefault()
    props.setAreaRightContent('')
    props.setIsAreaRightActive(false)
  }

  const handleGroupToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }


  // Добавляем команды в объект (responsibleTeam)
  const handleAddTeamForRes = (UUID) => {
    teams.map((item, index) => {
      if (item.UUID === UUID) {
        setResponsible(item);
        objectData.responsible = UUID
        setObjectData(objectData)
      }
    })
    
  }


  // Добавляем пользователя в объект (responsibleEmployee)
  const handleAddUserForRes = (UUID) => {
    if (UUID === false) {
      setObjectData([])
      setResponsible({});
      return;
    }
     users.map((item, index) => {
      if (item.UUID === UUID) {

        objectData.responsible = UUID

        setResponsible(item);
        setObjectData(objectData)
        setUsers(users);

        return true
      }
    })
    
  }

  return (
  	<div key={props.index ?? '1'}>

      <Typography gutterBottom variant="h5" component="div">
        {props.item ? 'Edit object' : 'New Object' } 
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {props.item ? 'Edit the object' : 'Add a new object' }
      </Typography>

      <div className="hr mx--3"></div>

      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                    Object name *
                </InputLabel>
            <div>
              <Input
                type="text"
                inputRef={refTitle}
                className="form-control w-100"
                id="outlined-required"
                label="title" 
                defaultValue={objectData.title} 
                name="title" 
                onChange={handlerChooseData}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="mt-2"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                Locations
              </InputLabel>
            <div>
              <TextareaAutosize
                  placeholder="Addresses"
                  defaultValue={objectData.adress} 
                  name="adress" 
                  className="form-control"
                  onBlur={ handlerChooseData }
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="mt-2"></div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                    Description
              </InputLabel>
            <div>
              <TextareaAutosize
                  placeholder="Description"
                  defaultValue={objectData.description} 
                  name="description" 
                  className="form-control"
                  onBlur={ handlerChooseData }
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} className="pt-0">
          <div className="pt-2">
            
            <div>
              <InputLabel shrink htmlFor="bootstrap-input">
                    Object group
              </InputLabel>
              <FormControl sx={{ m: 0 }} className="w-100">
                <Select className="form-select" defaultValue={objectData.parentObj ? objectData.parentObj.UUID : 'none'} name="parent" id="grouped-select" label="Grouping" onChange={handlerChooseData}>
                  <MenuItem value="none">
                    <em>Group not choose</em>
                  </MenuItem>
                  {objectGroupsAll?.map((item, index) => {
                    //if (item.ParentString === '0') {
                      return <MenuItem key={index} value={item.UUID} className="select-group">{item.title}</MenuItem>
                    //} else {
                      //return getGroupsForParent(item.UUID)
                    //}
                  })}
                </Select>
              </FormControl>

            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} className="pt-0">
          <div className="pt-2">
            <div className="lw-100">
              
              <InputLabel shrink htmlFor="bootstrap-input">
              <div className="w-100">
                
                <div>Responsible</div>
                

              </div>
              </InputLabel>

              <Box sx={{ flexGrow: 0 }} className="mr-4">
            
                <Menu
                  sx={{ mt: '20px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className="dropdown-w"
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserGroup}
                >

                {teams?.map((item, index) => {
                  const labelId = `enhanced-teams-checkbox-${index}`;
                  return <MenuItem key={index} value={item.UUID} onClick={() => {handleAddTeamForRes(item.UUID)}}>
                    
                    <Checkbox
                      color="primary"
                      checked={item.status ?? false}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    /> {item.title}

                  </MenuItem>
                })}

            </Menu>
          </Box>

              <div className="mt-1">
                
                {!getResponsible || getResponsible.UUID === null || getResponsible.UUID === undefined ? <div className="text-small">No responsible selected</div> : 
                  
                  <div className="lineItemDel flex acenter jbetween">
                    <div>{getResponsible.title ?? ''}</div>
                    <div className="btn-delete" onClick={() => {handleRemoveGroup()}}><MdDelete /></div>
                  </div>
                }

              </div>

            </div>

            {!getResponsible || getResponsible.UUID === null || getResponsible.UUID === undefined ? <a href="" onClick={handleClickOpen}>Add Responsible</a> : 
                <a href="" onClick={handleClickOpen}>Edit Responsible</a>
            }

          </div>
        </Grid>
      </Grid>


      

      

      <div className="hr mx--3"></div>

      <Stack spacing={2} direction="row">

        {props.item ? <Button className="btn" variant="contained" onClick={updateHandler}>Save object</Button> : 
        <Button className="btn" variant="contained" onClick={createHandler}>Create object</Button> }

        
        <Button className="btn" variant="outlined" onClick={cancelHandler}>Cancel</Button>
      </Stack>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
      >
        <DialogContent>

          
          <ModalUsers users={users} teams={teams} handleAddTeamForRes={handleAddTeamForRes} handleAddUserForRes={handleAddUserForRes} handleClose={handleClose} />

        </DialogContent>

      </Dialog>

    </div>

  );
};
