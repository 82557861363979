import axios from 'axios';
import { useQuery } from 'react-query'
import {Get, Find, naumenEdit, naumenGet, naumenGetSingle, naumenCreate, naumenDelete, naumenDestroy, Request} from '../../api/Naumen';

export const createTeam = (query = {}, attrs = '') => {
  query.metaClass = query.metaClass ?? 'team$team'
  return naumenCreate('team$team', query);
}

export const getTasks = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('task$task', query);
}

export const getTeams = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('team$team', query);
}

export const getUsers = (query = {}, attrs = '') => {
  query.removed = query.removed ?? 0
  return naumenGet('employee$employee', query);
}

export const createTask = (query = {}, attrs = '') => {
	query.ParentString = query.ParentString ?? 0
    return naumenCreate('task$task', query);
}

export const updateTask = (UUID, query = {}, attrs = '') => {
    return naumenEdit(UUID, query);
}

export const deleteTask = (UUID) => {
  return naumenDelete(UUID);
}


