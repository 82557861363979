import React, { useState } from 'react';

const Text = (props) => {
	
    return (
        <>
         
	         <div className="QuizContent__title">
	         	{ props.item.title }
	         </div>
	         <div className="QuizContent__body">
		         { props.item.answer }
	         </div>
	         
        </>
      );
  
};

export default Text