import React, { useState, useEffect, useRef } from 'react';
import { getMultipleVariants, updateMultipleVariant } from '../Queries';

const MultipleItem = (props) => {

	const refItem = useRef(null)
	const [ mounted, setMounted ] = useState(props.variant.status)

	useEffect(() => {
		
		if (mounted === false) {
			refItem.current.style.backgroundColor = ''
			refItem.current.style.color = ''
		}
		if (mounted === true) {
			let backgroundColor = refItem.current.dataset.color
			let color = '#000000'
			if (backgroundColor === '#000000') {
				color = '#eeeeee'
			}
			refItem.current.style.backgroundColor = backgroundColor
			refItem.current.style.color = color
		}


	}, [mounted])

	const clickBtn = (e) => {
		let backgroundColor = e.target.dataset.color
		const mount = mounted === true ? false : true

		setMounted(mount)

		if (props.work) {
			updateMultipleVariant(props.variant.UUID, {'status': mount}).then((item) => {
				if (item.data.UUID !== null && item.data.UUID !== undefined) {
					props.setSaveResult(true)
				}
			}).catch(() => {
				props.setSaveResult(false)
			})
		}
		
	}

    return (
        <button key={props.variant.UUID} ref={refItem} className="QuizContent__btn" onClick={clickBtn} data-color={props.variant.color} data-mounted={mounted} >{ props.variant.title }</button>
      );
  
};

export default MultipleItem