import { /*useHistory,*/ useParams, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import { FiArrowLeft } from "react-icons/fi";
import Typography from '@mui/material/Typography';
import QuizInspection from '../../components/Quiz/QuizInspection';
import { 
  getInspection,
  getChecklist,
  getChecklistPages
} from './InspectionsQuery';
import Fab from '@mui/material/Fab';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const InspectionSingle = ({ 
  isAdmin,
  pageActive,
  setPageActive
}) => {
  
  const { inspectionUUID } = useParams();
  const [ inspection, setInspection ] = useState({})
  const [ pages, setPages ] = useState([])
  const [ quizQuestions, setQuizQuestions ] = useState([])
  const [ getQuiz, setQuiz ] = useState('');
  const [ checklist, setChecklist ] = useState([]);
  const [ isLoadingStart, setLoadingStart ] = useState(true)
  const [ refReview, setRefReview] = useState([]);
  const [ test, setTest] = useState(false);
  const [ saveResultText, setSaveResultText] = useState(null);

  useEffect(() => {
    setPageActive('inspection')
    getInspection(inspectionUUID).then((item) => {
      setInspection(item)

    })

  }, [inspectionUUID])


  useEffect(() => {

    if (inspection.mnOriginCheckL !== undefined && inspection.mnOriginCheckL !== null) {

      let checklistUUID = inspection.mnOriginCheckL.UUID;
      setSaveResultText('Last modified ' + inspection.lastModifiedDate)

      let isMounted = false
      if (!isMounted) {
        getChecklist(checklistUUID).then((item) => {
          setChecklist(item)
        })
        getChecklistPages({parent: checklistUUID}).then((items) => {
          setLoadingStart(false)
          const pagesSort = items?.sort((a, b) => a.sort - b.sort)
          setPages(pagesSort)
        })
      }

      return () => { isMounted = true }
    }

  }, [inspection, quizQuestions])

  useEffect(() => {
    if (pages.length > 0) {
        setQuiz(<QuizInspection pages={pages} quizQuestions={quizQuestions} setQuizQuestions={setQuizQuestions} refReview={refReview} setRefReview={setRefReview} test={test} setTest={setTest} work={true} setSaveResult={setSaveResult} inspection={inspection} />)
    }
  }, [pages, setPages, quizQuestions]);

  const history = useHistory()

  const Redirect = (url) => {
    history.push(url)
  }

  const setSaveResult = (result) => {
    if (result === true) {
      setSaveResultText('Saved')
      setTimeout(() => {
        setSaveResultText('Last modified ' + inspection.lastModifiedDate)
      }, 2000)

    } else if (result === false) {
      setSaveResultText('Error')
    } else {
      //
    }

  }


  return (
    <>

      <div className="topBack flex acenter">
        <div>
           <Fab className="accordion-toggle-section" onClick={(e) => {Redirect('/inspection')}}>
             <FiArrowLeft />
           </Fab>
         </div>

        {inspection.conducted ? 
          <div className="ml-2">
              <Typography variant="h5" component="div">
                Back to list
              </Typography>
          </div>
        : 
          <div className="ml-2">
              <Typography variant="h5" component="div">
                {inspection.title}
              </Typography>
              <div className="InspectionSaveResult">{ saveResultText }</div>
          </div>
        }
        
      </div>

      <div className="quizBlock quizBlockWork">{ getQuiz }</div>

    </>
  );
};

