import React from 'react';
import { TypeNumber } from './TypeNumber';
import { TypeText } from './TypeText';
import { TypeDateTime } from './TypeDateTime';
import { TypeCheckbox } from './TypeCheckbox';
import { TypeSlider } from './TypeSlider';
import { TypeMultiple } from './TypeMultiple';
import { TypeMedia } from './TypeMedia';

export const TypeForCard = (props) => {

	let ContentType = ''

	switch(props.name) {
		case 'text':
			ContentType = <TypeText typeCode={ props.name } { ...props } />
			break;
		case 'number':
			ContentType = <TypeNumber typeCode={ props.name } { ...props } />
			break;
		case 'dateTime':
			ContentType = <TypeDateTime typeCode={ props.name } { ...props } />
			break;
		case 'checkbox':
			ContentType = <TypeCheckbox typeCode={ props.name } { ...props } />
			break;
		case 'slider':
			ContentType = <TypeSlider typeCode={ props.name } { ...props } />
			break;
		case 'media':
			ContentType = <TypeMedia typeCode={ props.name } { ...props } />
			break;
		case 'multiple':
			ContentType = <TypeMultiple typeCode={ props.name } { ...props } />
			break;
		default:

		break;
	}

	return (
		<div>
		    {ContentType}
		</div>
	);

};
