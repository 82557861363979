import "./Quiz.css";
import React, { useState, useEffect, useRef } from 'react';
import Items from './Items';
import ItemsConducted from './ItemsConducted';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { updateInspection } from './Queries';

const Quiz = (props) => {

  const work = props.work ?? false;
  const setSaveResult = props.setSaveResult ?? (() => {});
  const inspection = props.inspection ?? null;

  const [activePage, setActivePage] = useState(props.pages[0].UUID);
  const [activePageNum, setActivePageNum] = useState(1);
  const countPages = props.pages.length;

  const nextPage = (event) => {
    const num = activePageNum+1

    props.pages.map((item, index) => {
      if ((index+1) === num) {
        setActivePage(item.UUID)
        setActivePageNum(num)
      }
    })
  }
  const prevPage = (event) => {
    const num = activePageNum-1
    props.pages.map((item, index) => {
      if ((index+1) === num) {
        setActivePage(item.UUID)
        setActivePageNum(num)
      }
    })
  }

  const handleChange = (event) => {
    setActivePage(event.target.value);
    props.pages.map((item, index) => {
      if (event.target.value === item.UUID) {
        setActivePageNum(index + 1)
      }
    })
    props.setTest(event.target.value)
    
  };

  const [ questions, setQuestions ] = useState('')


  useEffect(() => {
    let isMounted = false
    let getQuestions = ''

    if (!isMounted) {


      // Если проверка уже завершена
      if (inspection !== null && inspection.conducted) {
        getQuestions = props.pages?.map((page, index) => {
          return (<div key={'pageIndex_' + index} className={activePage === page.UUID ? "PreviewContent-page active" : "PreviewContent-page"}><ItemsConducted { ...props } work={work} pageItem={page} /></div>)
        })
      } else {
        getQuestions = props.pages?.map((page, index) => {
          return (<div key={'pageIndex_' + index} className={activePage === page.UUID ? "PreviewContent-page active" : "PreviewContent-page"}><Items { ...props } work={work} pageItem={page} /></div>)
        })
      }

      setQuestions(getQuestions)
    }
      
    return () => { isMounted = true }
  }, [props.quizQuestions, props.pages, activePageNum]) 


  useEffect(() => {

    if (props.test) {
      
        handleChange({
          target: {
            value: props.test
          }
        })
    }

  }, [props.test])

  const Complete = () => {
    if (props.work) {
      // Закрываем проверку
      updateInspection(inspection.UUID, {'conducted': true}).then((item) => {
        window.location.href = "/inspection";
      })
      console.log(inspection)
    }

  }

      return (
        <>

         <div className="QuizPageItems QuizContentPreview">
          <div className="rightPreview-select">
            <div className="rightPreview-select-num">Page {activePageNum} of {props.pages.length}</div>

            <FormControl>
              <Select
                displayEmpty
                labelId="demo-select-small"
                id="demo-select-small"
                value={activePage}
                onChange={handleChange}
              >

                { props.pages?.map((page) => {
                  return (<MenuItem {...props} key={ page.UUID } value={ page.UUID }>{ page.title }</MenuItem>)
                }) }

              </Select>
            </FormControl>



          </div>
          <div className="PreviewContent">

            <div className="PreviewContent-scroll">

              { questions }

              
                <div className="PreviewContent-btns flex jbetween mt-3 mb-3">
                
                {activePageNum > 1 ?
                  <Button className="btn" variant="contained" onClick={prevPage}>Previous Page</Button>
                  : <div></div>}

                { activePageNum < countPages ?
                  <Button className="btn" variant="contained" onClick={nextPage}>Next Page</Button>
                  : <div></div> 
                }

                {inspection !== null && activePageNum === countPages && props.work ? 
                    <Button className="btn" variant="contained" onClick={Complete}>Complete Inspection</Button>
                : ''}

                </div>
              
            </div>

          </div>

         </div>

        </>
      );
  
};

export default Quiz