import "./Quiz.css";
import React, { useState, useEffect } from 'react';
import { 
  getQuestions 
} from './Queries';
import ItemQuestion from './Items/ItemQuestion';
import ItemSection from './Items/ItemSection';

const Items = (props) => {

	const [ questions, setQuestions] = useState([])
	const [ isReload, setReload] = useState(false)

	useEffect(() => {
		
		if (props.quizQuestions.length === 0) {
			// Получаем объекты вопросов
			let query = {}
			if (props.query === undefined || props.query === null) {
				query = {"parentPage": props.pageItem.UUID}
			} else {
				query = props.query
			}
			getQuestions(query)
		      .then((questions, index) => {

		      	const questionsSort = questions?.sort((a, b) => a.sort - b.sort)
		      	setQuestions(questionsSort)
		      	
		        
		    })
		} else {
			setReload(true)
		}

	}, [props.quizQuestions])



    return (
        <>
         {isReload ? props.quizQuestions?.map((question, index) => {
         	if (question.metaClass === 'mnRequirement$reqGroup') {
         		return (<ItemSection key={'page_section_'+index} { ...props } item={question} />)
         	} else {
         		console.log(question)
         		return (<ItemQuestion key={'page_question_'+index} { ...props } item={question} />)
         	}
         	
         }) : ''}

         {!isReload ? questions?.map((question, index) => {
         	if (question.metaClass === 'mnRequirement$reqGroup') {
         		return (<ItemSection key={'page_section_'+index} { ...props } item={question} />)
         	} else {
         		
         		return (<ItemQuestion key={'page_question_'+index} { ...props } item={question} />)
         	}
         	
         }) : ''}
        </>
    );
  
};

export default Items