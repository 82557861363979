import React, {useState, useEffect} from 'react';
import './Tasks.css';
import { TasksItem } from './TasksItem';
import { FormAddTask } from './forms/FormAddTask';
import { 
  getTasks, 
} from './TasksQuery';
import Button from '@mui/material/Button';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import {
  Redirect,
} from "react-router-dom";


const TasksBlock = (props) => {

  const [ tasks, setTasks ] = useState([]);
  const [ taskGroups, setTaskGroups ] = useState([]);

  const [ isLoading, setLoading ] = useState(true);
  const [ isError, setIsError ] = useState(false);
  const [ isSortableNumeric, setSortableNumeric ] = useState(false);
  const [ isSortableTitle, setSortableTitle ] = useState(false);
  const [ isSortableModified, setSortableModified ] = useState(false);
  const [ error, setError ] = useState('');

  const [ redirect, setRedirect ] = useState(false);

  useEffect(() => {
        getTasks({ ParentString: props.parent }).then(items => {
          
            let tasksSort = items?.sort((a, b) => -1)
            tasksSort?.map((it, index) => {it.index = index; return it})
            setTasks(tasksSort)
            setLoading(false)

        }).catch(err => {
          setIsError(true)
          setError(err)
        })
  }, [props.isListChoosed]) 

  if (isLoading) return <h1>...</h1>;

  if (isError) return <h1>{error.message}</h1>;

  
  

  const sortableModified = () => {
    let taskGroupsNew = [...taskGroups]
    let tasksNew = [...tasks]
    if (!isSortableModified) {
      taskGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
      tasksNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
    } else {
      taskGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
      tasksNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.creationDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
    } 
    setSortableModified(!isSortableModified);
    setSortableTitle(false);
    setSortableNumeric(false);
    setTaskGroups(taskGroupsNew);
    setTasks(tasksNew);
  }

  const sortableTitle = () => {
    
    let taskGroupsNew = [...taskGroups]
    let tasksNew = [...tasks]
    if (!isSortableTitle) {
      tasksNew.sort((a, b) => a.sort - b.sort).sort((a, b) => a.title.localeCompare(b.title));
      taskGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => a.title.localeCompare(b.title));
    } else {
      tasksNew.sort((a, b) => a.sort - b.sort).sort((a, b) => b.title.localeCompare(a.title));
      taskGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => b.title.localeCompare(a.title));
    }
    setSortableTitle(!isSortableTitle)
    setSortableNumeric(false);
    setSortableModified(false);
    setTaskGroups(taskGroupsNew);
    setTasks(tasksNew);
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }


  return (
    
      <div>

        {taskGroups.length > 0 || tasks.length > 0 ? 
        <div className="table-default-wrap">
        <div className="table-default table-tr-pointer" cellSpacing="0" cellPadding="0">
          <div className="table-default-thead">
            <div className="table-default-tr flex acenter jbetween">
              <div className="table-default-th table-default-title flex acenter" onClick={sortableTitle}>Task {isSortableTitle ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th">Number</div>
              <div className="table-default-th">Responsible</div>
              <div className="table-default-th flex acenter" onClick={sortableModified}>Creation date {isSortableModified ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th table-default-btns-object"></div>
            </div>
          </div>
          <div className="table-default-tbody">

              {tasks?.map((taskItem, index) => (

                  <TasksItem 
                    key={taskItem.UUID} 
                    index={index}
                    item={taskItem} 
                    setRedirect={setRedirect} 
                    setLoading={setLoading} 
                    setTasks={setTasks} 
                    tasks={tasks} 
                    setAreaRightContent={props.setAreaRightContent} 
                    setIsAreaRightActive={props.setIsAreaRightActive} 
                    handlerToggleFormAddTask={props.handlerToggleFormAddTask}
                  />
  
              ))}

          </div>
        </div>
        </div>

        : <div className="AlertInfo">Not found tasks</div>
      }

    </div>
  );
};

export default TasksBlock;