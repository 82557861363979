import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const Note = (props) => {

    return (
        <form className="QuizNote" onSubmit={props.saveNote}>

		    <div className="QuizNote-body">
		    	<TextareaAutosize className="form-control" name="noteText" defaultValue={props.noteText} />
			</div>
			<div className="QuizNote-btns">
		    	<Stack spacing={2} direction="row">
			      <Button className="btn" type="submit" variant="contained">Save</Button>
			      <Button className="btn" variant="outlined" onClick={props.toggleNote}>Cancel</Button>
			    </Stack>
			</div>
		    
        </form>
      );
  
};

export default Note
