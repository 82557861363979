import React from 'react';
import './ProjectPage.css';

export const ProjectPage = () => {

  return (
    <div>
      
      ProjectPage

    </div>
  );
};
