import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { getMultipleVariants } from '../ChecklistQuery';
import Chip from '@mui/material/Chip';

export const QuestionTypesItem = ({ groupMultiple }) => {

	const [items, setItem] = useState(false)
    
    useEffect(() => {
    	let isMounted = true;
		if (isMounted) {
			
			if (groupMultiple.UUID !== undefined && items === false) {
				getMultipleVariants({ groupMultiple: groupMultiple.UUID, removed: 0 }).then(its => {
					const itemsSort = its?.sort((a, b) => a.sort - b.sort)
					if (isMounted) {
						setItem(itemsSort)	
					}
				})
			}
		}
		return () => { isMounted = false };
	}, [items])

    const SChip = styled(
	    Chip,
	  )(({ txtcolor, bgcolor }) => ({
	    '&': {
	      backgroundColor: bgcolor,
	    },
	    '& .MuiChip-label': {
	      color: txtcolor,
	      fontSize: '12px',
	    },
	}));
    
    const random = Math.floor(Math.random() * (99999999 - 11111 + 1) + 5)

	return (
		<div key={groupMultiple.UUID+random}>
		{items.length > 0 ?
				items.map((multiple) => {
					return ( <SChip key={multiple.UUID+random} className="mr-05" label={multiple.title} size="small" txtcolor={multiple.color === '#000000' || multiple.color === '#111111' || multiple.color === '#222222' || multiple.color === '#333333' ? '#eeeeee' : '#000000'} bgcolor={multiple.color} /> )
				})
		: 'No options'
		}
		</div>
	);

};
