import { naumenGet, naumenGetSingle, naumenEdit } from '../../api/Naumen';

export const getQuestion = (UUID) => {
 return naumenGetSingle(UUID);
}

export const getMultipleVariants = (query = '') => {
  return naumenGet('mnMultipleResp$mnReqConf', query);
}

export const getSection = (UUID) => {
 return naumenGetSingle(UUID);
}

export const getQuestions = (query) => {
 return naumenGet('mnRequirement', query);
}

export const updateMultipleVariant = (UUID, query) => {
 return naumenEdit(UUID, query);
}

export const updateQuestion = (UUID, query) => {
 return naumenEdit(UUID, query);
}

export const updateInspection = (UUID, query) => {
 return naumenEdit(UUID, query);
}
