import React, {useState, useEffect} from 'react';
import { 
	updateMultipleVariant, 
	createMultipleVariant, 
	deleteMultipleVariant
} from './ControlTypeQuery';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { SettingTypeMultipleItem } from './SettingTypeMultipleItem'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { 
	changeTypeForQuestionMultiple
} from './ControlTypeQuery';
import { 
	getMultipleVariants, 
	saveSortableVariants
} from '../../ChecklistQuery';
import { MultipleGroupsCard } from '../MultipleGroupsCard'
import { TypeForCard } from './ControlTypeForCard'
import Quiz from '../../../../components/Quiz/Quiz';

export const SettingTypeMultiple = (props) => {

	const groupMultipleUUID = props.groupMultiple.UUID
	const [variants, setVariants] = useState([])
	const [isAmountVariants, setAmountVariants] = useState(false)
	const [getBlockVariants, setBlockVariants] = useState('')

	const onDragEnd = (param) => {
      const srcI = param.source.index;
      const desI = param.destination?.index;
      variants.splice(desI, 0, variants.splice(srcI, 1)[0])
      setVariants(variants)
      saveSortableVariants(variants)
  	};

	useEffect(() => {
		
		const variantsAll = variants

		if (variantsAll.length === 0 && isAmountVariants === false) {
			getMultipleVariants({ groupMultiple: groupMultipleUUID, removed: 0 }).then(items => {

					const variantsSort = items?.sort((a, b) => a.sort - b.sort)
					setVariants(variantsSort);
					setAmountVariants(true)

			}).catch(err => {})
		}
		
		const BlockVariants = () => {
	      return (<DragDropContext onDragEnd={onDragEnd}>
	        <Droppable droppableId={"droppable-"+groupMultipleUUID}>
	          {(provided, _) => (
	            <div ref={provided.innerRef} {...provided.droppableProps} >
	              {variantsAll?.map((variant, index) => {
	              	
	              	variant._focus = variant._focus ?? false
					variant._status = variant._status ?? true
					variant.color = variant.color ?? '#CCCCCC'
					variant.score = variant.score ?? 0
					
					return(<Draggable key={variant.UUID+index} draggableId={`draggable-${variant.UUID+index}`} index={index}>
	                  {(provided, snapshot) => (
	                    <div 
	                      ref={provided.innerRef}
	                      {...provided.draggableProps}
	                    >
							{ variant._status !== 'delete' ? 
								
								<SettingTypeMultipleItem key={index} indexItem={index} provided={provided} variant={variant} variants={variants} setVariants={setVariants} />

							: '' }

	                    </div>
	                  )}
	                </Draggable>
	              )})}
	              {provided.placeholder}
	            </div>
	          )}
	        </Droppable>
	      </DragDropContext>)
	    }

	    const contentBlockVariants = BlockVariants()
	    setBlockVariants(contentBlockVariants)
	    


	}, [props.groupMultiple, groupMultipleUUID, variants, isAmountVariants])

	

	const createMultipleVariantHandler = (e) => {
		const newItem = {
			UUID: false,
			_focus: false,
			_status: 'new',
			score: 0,
			title: 'Variant',
			color: '#CCCCCC',
		}
	  	setVariants([...variants, newItem]);
	}

	const saveHandler = (e) => {

		// Сохраняем тип multiple для вопроса
		changeTypeForQuestionMultiple(props)
		  .then((item) => {
		  	props.groupMultiple.groupMultiple = item.data.groupMultiple
		  	const groupMultiples = [ props.groupMultiple ]
			var typeTitleData = props.typeItem.title ?? 'Choose Type'
			//if (props.questionItem.groupMultiple) {
			  typeTitleData = <MultipleGroupsCard key={props.questionItem.UUID+Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 15)} { ...props } multipleGroups={groupMultiples} />
			  //props.setQuiz(<Quiz key={Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 15)} { ...props } pageItem={props.pageItem} />)
			//}

			props.setTypeTitle(typeTitleData)

		  	props.setQuizQuestions([])
		  })


		
		props.setContainerTypesShow(false)
		const AccordionContent = <TypeForCard 
		        name='multiple' 
		        { ...props } 
		        />
		props.setTypeSetting(AccordionContent)


		e.preventDefault()
		variants?.map((variant, indexItem) => {
			props.setAreaRightContent('')
			props.setIsAreaRightActive(false)

			const query = {
				title: variant.title ?? '',
				score: variant.score ?? 0,
				color: variant.color ?? null,
				flagged: variant.flagged ?? false
			}
			if (variant._status === 'delete') {
				return deleteMultipleVariant(variant.UUID)
			}
			if (variant._status === 'new') {
				query.groupMultiple = groupMultipleUUID
				return createMultipleVariant({variantUUID: variant.UUID, query})
			}
			if (variant._status === true) {
				return updateMultipleVariant({variantUUID: variant.UUID, query})
			}
			
			return true;
		})

	}

	const cancelHandler = (e) => {
		e.preventDefault()
		props.setAreaRightContent('')
		props.setIsAreaRightActive(false)
	}

	

	

	return (
		<div>

			<Typography gutterBottom variant="h5" component="div">
	            Multiple choice responses
	        </Typography>
	        <Typography variant="body2" color="text.secondary">
	            e.g. Yes, No, N/A
	        </Typography>

	        <div className="pt-3">
	        <Typography variant="body2" color="text.secondary">
	            Response
	        </Typography>
			</div>

			<div className={'multiple-variant-items'}>
				
				{getBlockVariants}
				
			</div>

			<div className="pt-1"></div>

			<Link className="link" onClick={createMultipleVariantHandler} >+ Add Response</Link>
			
			<div className="pt-3"></div>

			<Stack spacing={2} direction="row">
		      <Button className="btn" variant="contained" onClick={saveHandler}>Save and apply</Button>
		      <Button className="btn" variant="outlined" onClick={cancelHandler}>Cancel</Button>
		    </Stack>


		</div>
	);

};
