import React, { useState, useEffect, useRef } from 'react';
import Multiple from '../TypesConducted/Multiple';
import Text from '../TypesConducted/Text';
import Number from '../TypesConducted/Number';
import Checkbox from '../TypesConducted/Checkbox';
import DateTime from '../TypesConducted/DateTime';
import Media from '../TypesConducted/Media';
import Slider from '../TypesConducted/Slider';
import AddonNote from './Addons/Note';
import AddonMedia from './Addons/Media';
import { MdCollections } from "react-icons/md";
import axios from 'axios';
import { getMultipleVariants, updateQuestion } from '../Queries';

const ItemQuestionConducted = (props) => {

	const [ isLoading, setLoading ] = useState(true)
	const [ multipleVariants, setMultipleVariants ] = useState([])
	const [ files, setFiles ] = useState([])

	const [ isActiveNote, setIsActiveNote ] = useState(false)
	const [ isActiveMediaBtn, setIsActiveMediaBtn ] = useState(true)
	const [ noteText, setNoteText ] = useState(props.item.noteText ?? '')

	const fileInputRef = useRef();
	const blockRef = useRef(null);

	useEffect(() => {
		props.refReview[props.item.UUID] = blockRef
		props.setRefReview(props.refReview)

		if (props.item !== undefined && props.item !== null && props.item.typeResponse !== undefined && props.item.typeResponse !== null && props.item.typeResponse.code === 'media') {
		  	setIsActiveMediaBtn(false)
		}
		if (props.item !== undefined && props.item !== null && props.item.typeResponse !== undefined && props.item.typeResponse !== null && props.item.typeResponse.code === 'multiple') {
		  	let groupMultipleUUID = ''

			if (props.item.groupMultiple.UUID !== undefined) {
				groupMultipleUUID = props.item.groupMultiple.UUID
			}
			if (props.item.groupMultiple[0] !== undefined) {
				groupMultipleUUID = props.item.groupMultiple[0].UUID
			}
			if (groupMultipleUUID !== '' && groupMultipleUUID !== undefined) {
				getMultipleVariants({ groupMultiple: groupMultipleUUID, removed: 0 }).then(items => {

					const variantsSort = items.sort((a, b) => a.sort - b.sort)

					setMultipleVariants(variantsSort)

				}).catch(err => {})
			}

		}

	}, [props.item, props.quizQuestions])

	let getContent = ''

	if (props.item !== undefined && props.item !== null && props.item.typeResponse !== undefined && props.item.typeResponse !== null) {
	switch(props.item.typeResponse.code) {
	    case 'multiple':
	        getContent = <Multiple key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'text':
	        getContent = <Text key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'number':
	        getContent = <Number key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'checkbox':
	        getContent = <Checkbox key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'dateTime':
	        getContent = <DateTime key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'media':
	        getContent = <Media key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} files={files} setFiles={setFiles} fileInputRef={fileInputRef} />
	        break
	    case 'slider':
	        getContent = <Slider key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    default:
	        getContent = <Text key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	}
	}

    return (
        <>
        <div data-key={props.item.UUID} ref={blockRef}>
	        <div className={props.item.required ? "QuizContent QuizContent-Required" : "QuizContent"}>
	        	{ getContent }

	        	 {files.length > 0 ?
		         <AddonMedia { ...props } files={files} setFiles={setFiles} /> 
		         : '' 
		         }

		     	 {!isActiveNote && noteText !== '' ? 
		         	<div className="QuizNote-text mt-2">{ noteText }</div> 
		         : '' 
		         }
		         
	        </div>
        </div>
        </>
    );
  
};

export default ItemQuestionConducted