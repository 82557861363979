import React, { useState } from 'react';

const DateTime = (props) => {

	const [value, setValue] = useState(props.item.answer);

    return (
        <>

	         <div className="QuizContent__title">
	         	{props.item.title}
	         </div>
	         <div className="QuizContent__body">
		        {value}
	         </div>
	         
        </>
      );
  
};

export default DateTime