import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { changeRequired, getTypeItemForName } from './ControlTypeQuery';
import Link from '@mui/material/Link';

export const TypeMultiple = ({ 
		questionItem, 
		getSettingsType, 
		setAreaRightContent, 
		setIsAreaRightActive, 
		groupMultiple,
		setContainerTypesShow,
		setTypeTitle,
		setTypeSetting,
		setQuizQuestions 
	}) => {

	const [
		isCheckedRequired, 
		setCheckedRequired
	] = useState(questionItem.required);

	let activeSettingMultiple = false;
	if (questionItem.groupMultiple.length > 0) {
		activeSettingMultiple = true;
	}

	const [
		isActiveSettingMultiple, 
		setActiveSettingMultiple
	] = useState(activeSettingMultiple);

	const startGetSettingsType = () => {
		getTypeItemForName('multiple').then(typeItem => {
			getSettingsType({
				setAreaRightContent, 
				setIsAreaRightActive, 
				name: 'multiple', 
				typeItem: typeItem[0], 
				questionItem, 
				setTypeTitle, 
				groupMultiple,
				setContainerTypesShow, 
				getSettingsType, 
				setTypeSetting
			});
		}) 
	}

	return (
		<div className="flex acenter">
		{isActiveSettingMultiple ? 
			<>
		    <FormControlLabel
	          value="1"
	          control={<Checkbox checked={isCheckedRequired} onChange={() => changeRequired({
	          	questionUUID: questionItem.UUID, 
	          	setCheckedRequired,
	          	isCheckedRequired,
	          	setQuizQuestions
	          })} name="required" />}
	          label="Required"
	        />
	        
	        	<Link className="link" onClick={() => {startGetSettingsType(); setContainerTypesShow(false); }}>Edit responses</Link>
	        	</>

	        	: 'Please, choose type of the question' }
	        
	        

		</div>
	);

};
