import './ChecklistSingle.css';
import React, {useState, useEffect} from 'react';
import { AreaRight } from "../../../components/Area/Area";
import { QuestionTypes } from './QuestionTypes';
import { TypeForCard } from './types/ControlTypeForCard';
import { TypeForSetting } from './types/ControlTypeForSetting';
import Accordion from '../../../components/Accordion/Accordion';
import { updateQuestion, deleteQuestion } from '../ChecklistQuery';
import { MultipleGroupsCard } from './MultipleGroupsCard';
import Quiz from '../../../components/Quiz/Quiz';

export const QuestionItem = (props) => {

  var typeTitleData = ''
  var typeCodeData = ''
  var questionItemgroupMultiple = ''
  if (props.questionItem.groupMultiple !== undefined && props.questionItem.groupMultiple !== null) {
    questionItemgroupMultiple = props.questionItem.groupMultiple[0]
  }
  if (props.questionItem.typeResponse !== undefined && props.questionItem.typeResponse !== null) {
    
    typeTitleData = props.questionItem.typeResponse.title
    typeCodeData = props.questionItem.typeResponse.code

    if (props.questionItem.typeResponse.code === 'multiple') {
      if (props.questionItem.groupMultiple.length > 0) {
        typeTitleData = <MultipleGroupsCard key={props.questionItem.UUID} multipleGroups={props.questionItem.groupMultiple} />
      } else {
        typeTitleData = 'Choose Type'
      }
    } 

  } else {
    typeTitleData = 'Choose Type'
    typeCodeData = ''
  }

  const [typeTitle, setTypeTitle] = useState(typeTitleData);
  const [typeSetting, setTypeSetting] = useState('');

  const getSettingsType = (stypeData) => {
    AreaRight('open')
    const data = <TypeForSetting { ...props } { ...stypeData } getSettingsType={getSettingsType} setTypeSetting={setTypeSetting} />
    props.setAreaRightContent(data)
    props.setIsAreaRightActive(true)
  }

  const startSetTypeSetting = (e) => {
    setTypeSetting('')
    const AccordionContent = <TypeForCard 
        name={typeCodeData} 
        getSettingsType={getSettingsType} 
        groupMultiple={questionItemgroupMultiple}
        setTypeTitle={setTypeTitle} 
        setTypeSetting={setTypeSetting} 
        { ...props }
        />
    setTypeSetting(AccordionContent)

  }

  useEffect(() => {
    startSetTypeSetting()
  }, [])

  const getTypes = () => {
    AreaRight('open')

    const data = <QuestionTypes 
      setTypeTitle={setTypeTitle} 
      setTypeSetting={setTypeSetting} 
      getSettingsType={getSettingsType}  
      { ...props }
    />;

    props.setContainerTypes(data)
    props.setContainerTypesShow(true)
  }

  const updateQuestionTitle = (e) => {
    const text = e.target.innerHTML.replace(/(<([^>]+)>)/ig, '');

    const pageItem = props.questionItem.parentPage
    updateQuestion(props.questionItem.UUID, {title: text}).then((item) => {
      const showQuestionNew = props.showQuestion.map((question) => {
        if (question.UUID === props.questionItem.UUID) {
          item.data.isActive = true
          return item.data
        } else {
          return question
        }
      })
      
      props.setShowQuestion(showQuestionNew)
      props.setQuizQuestions([])
      
    })
    
  }

  const deleteItem = (e, UUID) => {    
    //const pageItem = props.questionItem.parentPage
    deleteQuestion(UUID).then((item) => {
      
      const showQuestionNew = props.showQuestion.map((question) => {
        if (question.UUID !== UUID) {
          return question
        }
      })

      props.setShowQuestion(showQuestionNew)
      props.setQuizQuestions([])

    })

    e.target.closest('.accordion-item').remove()
  }

  const AccordionHeaderContent = () => {
        return (
          <div key={props.questionItem.UUID} className={'flex acenter'} >
            <div className="ChecklistItemType flex acenter jbetween p-2">
              <span className="ChecklistQuestionTitle" onClick={() => getTypes()}>{typeTitle}</span>
            </div>
          </div>
        )
  }

  return (<Accordion type="question" key={props.questionItem.UUID} provided={props.provided} updateQuestionTitle={updateQuestionTitle} header=<AccordionHeaderContent /> content={typeSetting} title={props.questionItem.title} className={"ChecklistItem item"} questionItem={props.questionItem} deleteItem={deleteItem} />)
      
};
