import './ChecklistSingle.css';
import { /*useHistory,*/ useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { 
  getChecklist, 
  getChecklistPages, 
  createChecklistPage, 
  createChecklistQuestion,
  updateChecklist, 
  saveSortablePages
} from '../ChecklistQuery';
import { PageItem } from './PageItem';
import Link from '@mui/material/Link';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Quiz from '../../../components/Quiz/Quiz';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const ChecklistSingle = ({ 
  isAdmin, 
  setAreaRightContent, 
  setIsAreaRightActive, 
  containerTypes, 
  setContainerTypes,
  setContainerTypesShow,
  isContainerTypes, 
  setPageActive, 
  pageActive
}) => {

  const [ isLoadingStart, setLoadingStart ] = useState(true)

  const { checklistUUID } = useParams();
  const [ pages, setPages ] = useState([])
  const [ quizQuestions, setQuizQuestions ] = useState([])
  const [showQuestion, setShowQuestion] = useState([]);
  const [getChecklistContent, setChecklistContent] = useState('');

  const [ getQuiz, setQuiz ] = useState('');
  const [ checklist, setChecklist ] = useState([]);

  const createPageHandler = () => {
    createChecklistPage({
      title: 'Untitled Page',
      parent: checklistUUID,
    }).then(res => {
      createChecklistQuestion({parentPage: res.UUID})
      setPages([...pages, res])
    })

  }

  const updateChecklistTitle = (e) => {
    updateChecklist(checklistUUID, {
      title: e.target.value,
    })

  }
  const updateChecklistDescription = (e) => {
    updateChecklist(checklistUUID, {
      description: e.target.value,
    })

  }

  const onDragEnd = (param) => {
      const srcI = param.source.index;
      const desI = param.destination?.index;
      pages.splice(desI, 0, pages.splice(srcI, 1)[0])
      saveSortablePages(pages)
  };

  useEffect(() => {
    setPageActive('checklist')
  })

  useEffect(() => {
    let isMounted = false
    if (!isMounted) {
      getChecklist(checklistUUID).then((item) => {
        setChecklist(item)
      })
      getChecklistPages({parent: checklistUUID}).then((items) => {
        setLoadingStart(false)
        const pagesSort = items?.sort((a, b) => a.sort - b.sort)
        setPages(pagesSort)
      })
    }

    return () => { isMounted = true }

  }, [quizQuestions])

  const [ refReview, setRefReview] = useState([]);
  const [ test, setTest] = useState(false);

  const scrollToPreview = (questionUUID, pageUUID) => {

    setTest(pageUUID)

    setTimeout(() => {
      if (refReview[questionUUID].current !== null && refReview[questionUUID].current !== undefined) {
        refReview[questionUUID].current.scrollIntoView({
          behavior: "smooth",
          block: "center"
        })
      }
    }, 440)
    
  }

  useEffect(() => {
    let isMounted = false
    if (!isMounted) {
    if (pages.length > 0) {

        setQuiz(<Quiz pages={pages} quizQuestions={quizQuestions} setQuizQuestions={setQuizQuestions} refReview={refReview} setRefReview={setRefReview} test={test} setTest={setTest} />)
    }
    const ChecklistContent = () => {
        return (
<DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-1">
        {(provided, _) => (
          <div ref={provided.innerRef} {...provided.droppableProps} >
            {pages?.map((pageItem, index) => (
              <Draggable key={pageItem.UUID} draggableId={`draggable-${pageItem.UUID}`} index={index}>
                {(provided, snapshot) => (
                  <div 
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >

                    <PageItem key={pageItem.UUID} pages={pages} setShowQuestion={setShowQuestion} showQuestion={showQuestion} quizQuestions={quizQuestions} setQuizQuestions={setQuizQuestions} setPages={setPages} checklistItem={checklist} provided={provided} pageItem={pageItem} isAdmin={isAdmin} setAreaRightContent={setAreaRightContent} setIsAreaRightActive={setIsAreaRightActive} containerTypes={containerTypes} setContainerTypes={setContainerTypes} isContainerTypes={isContainerTypes} setContainerTypesShow={setContainerTypesShow} getQuiz={getQuiz} setQuiz={setQuiz} scrollToPreview={scrollToPreview} setTest={setTest} />

                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
        )
      }
      setChecklistContent(ChecklistContent)
    }

    return () => { isMounted = true }
  }, [pages, setPages, quizQuestions])

  if (isLoadingStart) return <h1>Loading..</h1>;
  if (checklist.title === null) return <h1>Loading...</h1>;

  return (
    <>

      <div className="rightPreview">
        <div className="rightPreview-title">
            <Typography gutterBottom variant="h5" component="div">
                Preview
            </Typography>
        </div>
        
        { getQuiz }

      </div>

      <div className="bodyContent">
      <div className="liveContent">
          <div>
            <TextareaAutosize
                placeholder="Checklist Name"
                defaultValue={checklist.title} 
                name="title" 
                className="inputChecklist"
                onBlur={ updateChecklistTitle }
            />
          </div>
          <div>
            <TextareaAutosize
                placeholder="+ Add description"
                defaultValue={checklist.description} 
                name="description" 
                className="form-textarea"
                onBlur={ updateChecklistDescription }
            />
          </div>

          {getChecklistContent}

          <div className="mt-2 flex acenter">
            <div className="mr-3">
              <Button className="btn" variant="contained" onClick={createPageHandler}>Add page</Button>
            </div>
            <Link className="link d-none" onClick={createPageHandler} >Move pages</Link>
          </div>
          
          <div className={ isContainerTypes ? "type-dropdown active" : "type-dropdown" }>
            <div className="type-dropdown-container">
              {containerTypes}
            </div>
          </div>
          <div className={ isContainerTypes ? "TransparentBg active" : "TransparentBg" } onClick={() => setContainerTypesShow(false)}></div>

        {isAdmin && (
          <div className='postControl'>
            
          </div>
        )}
      </div>
      </div>

    </>
  );
};

