import React, {useState} from 'react';
import reactCSS from 'reactcss'
import { TwitterPicker } from 'react-color'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { MdDragIndicator } from "react-icons/md";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export const SettingTypeMultipleItem = ({ indexItem, variants, setVariants, variant, provided }) => {
	
	const varianto = variant
	
	const [
		isCheckedFlagged, 
		setCheckedFlagged
	] = useState(variant.flagged ?? false);

	const focusHandler = (focus) => {
		let updatedList = variants.map((item, index) =>
	    {
	      if (index === indexItem) {
	      	item['_focus'] = focus
	      } else {
	      	item['_focus'] = false
	      }
	      return item;
	    });
	  	setVariants(updatedList);
	}

	const updateHandlerFlagged = (e) => {

		const isCheckedFlaggedNew = !isCheckedFlagged
		setCheckedFlagged(isCheckedFlaggedNew);

		let updatedList = variants.map((item, index) =>
	    {
	      if (index === indexItem){
	      	item['flagged'] = isCheckedFlaggedNew
	      }
	      return item;
	    });
	  	setVariants(updatedList);
	}

	const updateHandler = (e) => {

		let updatedList = variants.map((item, index) =>
	    {
	      if (index === indexItem){
	      	item[e.target.name] = e.target.value
	      }
	      return item;
	    });
	  	setVariants(updatedList);

	}

	const deleteHandler = (e) => {
		const res = window.confirm('Delete?');
		if (!res) return false
		var el = ''
		if (e.target.tagName === 'BUTTON') {
			el = e.target;
		} else {
			el = e.target.closest('button');
		}
		
		let updatedList = variants.map((item, index) =>
	    {
	      if (index === indexItem){
	      	item[el.name] = el.value
	      }
	      return item;
	    });
	  	setVariants(updatedList);
	}

    const [colors, setColors] = useState({
	    displayColorPicker: false,
	    color: variant.color ?? '#cccccc',
	});

	  const handleClick = () => {
	    setColors({...colors, displayColorPicker: !colors.displayColorPicker })
	  };

	  const handleClose = () => {
	    setColors({ ...colors, displayColorPicker: false })
	  };

	  const handleChange = (color) => {
	    setColors({ ...colors, color: color.hex })

	    let updatedList = variants.map((item, index) =>
	    {
	      if (index === indexItem){
	      	item.color = color.hex
	      }
	      return item;
	    });
	  	setVariants(updatedList);

	  };
    
    const styles = reactCSS({
      'default': {
        color: {
          width: '25px',
          height: '25px',
          border: '0px solid #ccc',
          boxShadow: '0 1px 2px 0 rgba(0,0,0,.1)',
          borderRadius: '50%',
          background: colors.color,
        },
        swatch: {
          padding: '0',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '12',
          marginTop: '5px',
          right: '12px',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },

      },
    });

		return (
			<div key={indexItem} className={ varianto._focus === true ? 'multiple-variant-item active mx--3' : 'multiple-variant-item mx--3' } onClick={()=>{ focusHandler(true)}}>
				<div className="">
				
				<div className="mvi-row flex jbetween">
					<div className="mvi-title flex jstart w-100">
						<span className="accordion-arrow accordion-drag accordion-drag-absolute flex acenter jcenter" {...provided.dragHandleProps}>
							<MdDragIndicator />
						</span>
						<TextareaAutosize
					      className="txtarea"
					      placeholder="Variant"
					      defaultValue={varianto.title} 
					      name="title" 
					      onBlur={ updateHandler }
					    />
					</div>
					<div className="mvi-color">
						<div className="mvi-color-circle">
							<div>
							    <div style={ styles.swatch } onClick={ handleClick }>
							        <div style={ styles.color }></div>
							    </div>
							    { colors.displayColorPicker ? <div style={ styles.popover }>
							    <div style={ styles.cover } onClick={ handleClose }></div>
							        <TwitterPicker triangle="top-right" colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#ffff00', '#CCCCCC', '#555555', '#000000']} color={ colors.color } onChange={ handleChange } />
							    </div> : null }

							</div>
						</div>
					</div>
				</div>
				</div>
				{ (varianto._focus === true) ?

				<div className="flex acenter jend">
					<FormControlLabel
			          value="1"
			          className="check-text-small" 
			          control={<Checkbox checked={isCheckedFlagged} onChange={updateHandlerFlagged}  name="flagged" />}
			          label="Mark flagged"
			        />
							<div>
						Score: <input type="text" className="form-input-small" defaultValue={varianto.score} name="score" onBlur={ updateHandler } />
					</div>
					<div className="ml-1">
						<IconButton aria-label="delete" size="small" 
						  value="delete" 
					      name="_status" 
					      onClick={ deleteHandler }>
					        <DeleteIcon fontSize="small" />
					    </IconButton>
					</div>
				</div>

				: 
				
				<div className="flex acenter jend">
					<div>
						{ (varianto.score !== '' && varianto.score !== null && varianto.score !== false && varianto.score !== 0) ? <div>Score: {varianto.score}</div> : '' }
					</div>
				</div>

				}
			</div>
		)

};
