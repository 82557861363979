import React from 'react';
import Stack from '@mui/material/Stack';
import { QuestionTypesItem } from './QuestionTypesItem';

export const MultipleGroupsCard = ({ multipleGroups }) => {	

	return (
		<div>

		{multipleGroups.map((groupMultiple, index) => {
                    	const random = Math.floor(Math.random() * (99999999 - 11111 + 1) + 5)
						if (groupMultiple.UUID !== null && groupMultiple.UUID !== undefined) {
							return (
								<div key={groupMultiple.UUID+random}>
					                <div className="flex jbetween acenter">
					                <Stack direction="row" spacing={.5}>
					                
					                    <QuestionTypesItem groupMultiple={groupMultiple} />

					                </Stack>
					                </div>
					            </div>

							);
						}

					})}
		    
		</div>
	);

};
