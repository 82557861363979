import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { changeRequired } from './ControlTypeQuery';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MdOutlineSettings } from "react-icons/md";
import Link from '@mui/material/Link';

export const TypeSlider = ({ 
		typeCode, 
		questionItem, 
		getSettingsType, 
		setAreaRightContent, 
		setIsAreaRightActive,
		setQuizQuestions 
	}) => {

	const [
		isCheckedRequired, 
		setCheckedRequired
	] = useState(questionItem.required);

	return (
		<div className="flex acenter">
		    
		    <FormControlLabel
	          value="1"
	          control={<Checkbox checked={isCheckedRequired} onChange={() => changeRequired({
	          	questionUUID: questionItem.UUID, 
	          	setCheckedRequired,
	          	isCheckedRequired,
	          	setQuizQuestions
	          })} name="required" />}
	          label="Required"
	        />

	        <Link className="link" onClick={() => getSettingsType({setAreaRightContent, setIsAreaRightActive, name: typeCode, questionItem})}>Range: {questionItem.min} - {questionItem.max}</Link>
	        
	        <div className="d-none">
	        <Tooltip title="Settings for type" placement="top" arrow >
                <IconButton color="secondary" onClick={() => getSettingsType({setAreaRightContent, setIsAreaRightActive, name: typeCode, questionItem})}>
                    <MdOutlineSettings />
                </IconButton>
            </Tooltip>
            </div>

		</div>
	);

};
