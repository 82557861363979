import React, { useState, useEffect } from 'react';
import Checkbox2 from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { updateQuestion } from '../Queries';

const Checkbox = (props) => {

	const [
		isCheckedRequired, 
		setCheckedRequired
	] = useState(props.item.answer === "1" ? true : false);

	

	const Save = (e) => {

		const value = !isCheckedRequired === false ? '0' : '1';
		
		setCheckedRequired(!isCheckedRequired)
		// сохраняем answer
		// только если рабочая версия
		if (props.work) {
			updateQuestion(props.item.UUID, {'answer': value}).then((item) => {
				if (item.data.UUID !== null && item.data.UUID !== undefined) {
					props.setSaveResult(true)
				}
			}).catch(() => {
				props.setSaveResult(false)
			})

		}

	}

    return (
        <>	  

	         <div className="QuizContent__body">
		        { props.item.required ? '*' : '' } <FormControlLabel
		          value="1"
		          control={<Checkbox2 checked={ isCheckedRequired } onChange={ Save } />}
		          label={props.item.title}
		        />
	         </div>
	         
        </>
      );
  
};

export default Checkbox