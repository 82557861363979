import React, { useState } from 'react';

const Number = (props) => {

    return (
        <>

	         <div className="QuizContent__title">
	         	{props.item.title}
	         </div>
	         <div className="QuizContent__body">
		         { props.item.answer }
	         </div>
	         
        </>
      );
  
};

export default Number