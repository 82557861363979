import "./Area.css";
import React from 'react';

export const AreaRight = ({ isActive, setIsActive, content }) => {

      return (
        <>
         <div>
          <div id="AreaRight" className={ isActive ? "Area AreaRight active" : "Area AreaRight"}>
            <div className="p-2 ajax-AreaRight">{content}</div>
          </div>
          <div className={ isActive ? "AreaBg active" : "AreaBg"} onClick={() => setIsActive(false)}></div>
          </div>
        </>
      );
  
};
