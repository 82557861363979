import React from 'react';
import { MdCreate } from "react-icons/md";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { QuestionTypesItem } from './QuestionTypesItem';
import { getTypeItemForName } from './types/ControlTypeQuery';

export const MultipleGroupsData = ({ multipleGroups, updateTypeForQuestionMultiple, getSettingsType, setContainerTypesShow, typeItemData, setAreaRightContent, setIsAreaRightActive, questionItem, setTypeTitle, setTypeSetting }) => {	

	const startGetSettingsType = ({ name, groupMultiple }) => {

		getTypeItemForName('multiple').then(typeItem => {
			getSettingsType({
				setAreaRightContent, 
				setIsAreaRightActive, 
				name, 
				typeItem: typeItem[0], 
				questionItem, 
				setTypeTitle, 
				groupMultiple,
				setContainerTypesShow, 
				getSettingsType, 
				setTypeSetting
			});
		})

	}

	return (
		<div>

		{multipleGroups.map((groupMultiple, index) => {
                    	const random = Math.floor(Math.random() * (99999999 - 11111 + 1) + 5)
						if (groupMultiple.UUID !== null && groupMultiple.UUID !== undefined) {
							return (
								<div key={groupMultiple.UUID+random} className="multiple-item">
					                <div className="flex jbetween acenter">
					                <Stack direction="row" spacing={.5} onClick={ ()=> updateTypeForQuestionMultiple({ typeItem: typeItemData, groupMultiple }) }>
					                
					                    <QuestionTypesItem groupMultiple={groupMultiple} />

					                </Stack>
					                <div>
					                	<IconButton aria-label="edit" size="small" onClick={() => {startGetSettingsType({ name: 'multiple', groupMultiple}); setContainerTypesShow(false)}} >
								            <MdCreate />
								        </IconButton>
								    </div>
					                </div>
					            </div>

							);
						}

					})}
		    
		</div>
	);

};
