import React, {useState, useEffect} from 'react';
import './Objects.css';
import { ObjectItem } from './ObjectItem';
import { ObjectGroupItem } from './ObjectGroupItem';
import { FormAddGroup } from './forms/FormAddGroup';
import { FormAddObject } from './forms/FormAddObject';
import { 
  getObjects, 
  getObjectGroups, 
} from './ObjectQuery';
import Button from '@mui/material/Button';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import {
  Redirect,
} from "react-router-dom";


const ObjectsBlock = (props) => {

  const [ objects, setObjects ] = useState([]);
  const [ objectGroups, setObjectGroups ] = useState([]);

  const [ isLoading, setLoading ] = useState(true);
  const [ isError, setIsError ] = useState(false);
  const [ isSortableNumeric, setSortableNumeric ] = useState(false);
  const [ isSortableTitle, setSortableTitle ] = useState(false);
  const [ isSortableModified, setSortableModified ] = useState(false);
  const [ error, setError ] = useState('');

  const [ redirect, setRedirect ] = useState(false);

  useEffect(() => {
        getObjectGroups({ ParentString: props.parent }).then(items => {

            let objectGroupsSort = items?.sort((a, b) => -1)
            objectGroupsSort?.map((it, index) => {it.index = index; return it})
            setObjectGroups(objectGroupsSort)
            setLoading(false)

        }).catch(err => {
          setIsError(true)
          setError(err)
        })

  }, [props.isListChoosed]) 

  useEffect(() => {
        getObjects({ ParentString: props.parent }).then(items => {
          
            let objectsSort = items?.sort((a, b) => -1)
            objectsSort?.map((it, index) => {it.index = index; return it})
            setObjects(objectsSort)
            setLoading(false)

        }).catch(err => {
          setIsError(true)
          setError(err)
        })
  }, [props.isListChoosed]) 

  if (isLoading) return <h1>...</h1>;

  if (isError) return <h1>{error.message}</h1>;

  
  

  const sortableModified = () => {
    let objectGroupsNew = [...objectGroups]
    let objectsNew = [...objects]
    if (!isSortableModified) {
      objectGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
      objectsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
    } else {
      objectGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
      objectsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
    } 
    setSortableModified(!isSortableModified);
    setSortableTitle(false);
    setSortableNumeric(false);
    setObjectGroups(objectGroupsNew);
    setObjects(objectsNew);
  }

  const sortableTitle = () => {
    
    let objectGroupsNew = [...objectGroups]
    let objectsNew = [...objects]
    if (!isSortableTitle) {
      objectsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => a.title.localeCompare(b.title));
      objectGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => a.title.localeCompare(b.title));
    } else {
      objectsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => b.title.localeCompare(a.title));
      objectGroupsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => b.title.localeCompare(a.title));
    }
    setSortableTitle(!isSortableTitle)
    setSortableNumeric(false);
    setSortableModified(false);
    setObjectGroups(objectGroupsNew);
    setObjects(objectsNew);
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }


  return (
    
      <div>

        {objectGroups.length > 0 || objects.length > 0 ? 
        <div className="table-default-wrap">
        <div className="table-default table-tr-pointer" cellSpacing="0" cellPadding="0">
          <div className="table-default-thead">
            <div className="table-default-tr flex acenter jbetween">
              <div className="table-default-th table-default-title flex acenter" onClick={sortableTitle}>Object {isSortableTitle ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th">Responsible</div>
              <div className="table-default-th flex acenter" onClick={sortableModified}>Last modified {isSortableModified ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th">Locations</div>
              <div className="table-default-th table-default-btns-object"></div>
            </div>
          </div>
          <div className="table-default-tbody">
          
              {objectGroups?.map((objectGroupItem, index) => (

                  <ObjectGroupItem 
                    key={objectGroupItem.UUID} 
                    index={index}
                    item={objectGroupItem} 
                    setRedirect={setRedirect} 
                    setLoading={setLoading} 
                    setObjectGroups={setObjectGroups} 
                    objectGroups={objectGroups} 
                    setAreaRightContent={props.setAreaRightContent} 
                    setIsAreaRightActive={props.setIsAreaRightActive} 
                    handlerToggleFormAddGroup={props.handlerToggleFormAddGroup} 
                    handlerToggleFormAddObject={props.handlerToggleFormAddObject}
                  />
  
              ))}

              {objects?.map((objectItem, index) => (

                  <ObjectItem 
                    key={objectItem.UUID} 
                    index={index}
                    item={objectItem} 
                    setRedirect={setRedirect} 
                    setLoading={setLoading} 
                    setObjects={setObjects} 
                    objects={objects} 
                    setAreaRightContent={props.setAreaRightContent} 
                    setIsAreaRightActive={props.setIsAreaRightActive} 
                    handlerToggleFormAddObject={props.handlerToggleFormAddObject}
                  />
  
              ))}

          </div>
        </div>
        </div>

        : <div className="AlertInfo">Not found objects</div>
      }

    </div>
  );
};

export default ObjectsBlock;