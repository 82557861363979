import {naumenEdit, naumenGet, naumenCreate} from '../../../../api/Naumen';

// Изменяем значение атрибута required в вопросе
export const changeRequired = ({ questionUUID, isCheckedRequired, setCheckedRequired, setQuizQuestions }) => {
  return naumenEdit(questionUUID, {
    "required": !isCheckedRequired
  }).then(() => {
    setCheckedRequired(!isCheckedRequired)
    setQuizQuestions([])
  })
  
}
export const changeShowDate = ({ questionUUID, isCheckedShowDate, setCheckedShowDate, setQuizQuestions }) => {
  return naumenEdit(questionUUID, {
    "showDate": !isCheckedShowDate
  }).then(() => {
    setCheckedShowDate(!isCheckedShowDate)
    setQuizQuestions([])
  })
  
}
export const changeShowTime = ({ questionUUID, isCheckedShowTime, setCheckedShowTime, setQuizQuestions }) => {
  return naumenEdit(questionUUID, {
    "showTime": !isCheckedShowTime
  }).then(() => {
    setCheckedShowTime(!isCheckedShowTime)
    setQuizQuestions([])
  })
}

// Изменяем параметры вопроса
export const updateQuestion = ({ questionUUID, query }) => {
  return naumenEdit(questionUUID, query);
}

// Получаем typeItem по имени типа
export const getTypeItemForName = (name) => {
  return naumenGet('nmReqTypes', {code: name})
}

// Создаем variant вопроса
export const createMultipleVariant = ({ query }) => {
  query.metaClass = query.metaClass ?? "mnMultipleResp$mnReqConf"
  query.sort = query.sort ?? 999
  naumenCreate('mnMultipleResp$mnReqConf', query);
}

// Создаем group multiple
export const createMultipleGroup = ( query ) => {
  query.metaClass = query.metaClass ?? "nmGroupMultipl$groupMultiple"
  query.title = query.title ?? "Group Multiple"
  return naumenCreate('nmGroupMultipl$groupMultiple', query);
}

// Изменяем параметры varianta вопроса multiple 
export const updateMultipleVariant = ({ variantUUID, query }) => {
  naumenEdit(variantUUID, query);
}

// Удаляем variant вопроса multiple 
export const deleteMultipleVariant = (variantUUID) => {

  naumenEdit(variantUUID, {
    "removed": true
  })
  
}

// Изменяем тип для вопроса
export const changeTypeForQuestion = ({ questionItem, typeItem }) => {

  return naumenEdit(questionItem.UUID, {
    "typeResponse": typeItem.UUID
  });
  
}

// Изменяем тип для вопроса
export const changeTypeForQuestionMultiple = ({ questionItem, typeItem, groupMultiple }) => {

  return naumenEdit(questionItem.UUID, {
    "typeResponse": typeItem.UUID,
    "groupMultiple": groupMultiple.UUID
  });
  
}
