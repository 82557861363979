import React, {useState, useEffect} from 'react';
import './Checklists.css';
import { ChecklistCard } from './components/ChecklistCard';
import { 
  getChecklists, 
  createChecklist, 
  deleteChecklist, 
  saveSortableChecklists
} from './ChecklistQuery';
import Button from '@mui/material/Button';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Redirect,
} from "react-router-dom";
import Swal from 'sweetalert2'


export const Checklists = ({ isAdmin, setPageActive, pageActive }) => {

  const [ checklists, setChecklists ] = useState([]);

  const [ isLoading, setLoading ] = useState(true);
  const [ isError, setIsError ] = useState(false);
  const [ isSortableNumeric, setSortableNumeric ] = useState(false);
  const [ isSortableTitle, setSortableTitle ] = useState(false);
  const [ isSortableModified, setSortableModified ] = useState(false);
  const [ error, setError ] = useState('');

  const [ redirect, setRedirect ] = useState(false);

  useEffect(() => {
    setPageActive('checklists')
  })

  useEffect(() => {
    let isMounted = false
      if (isLoading) {
        getChecklists().then(items => {
          if (!isMounted) {
            let checklistsSort = items?.sort((a, b) => a.sort - b.sort)
            checklistsSort?.map((it, index) => {it.index = index; return it})
            setChecklists(checklistsSort)
            setLoading(false)
          }
        }).catch(err => {
          setIsError(true)
          setError(err)
        })
      }
    return () => { isMounted = true }
  }, [isLoading]) 

  if (isLoading) return <h1>Loading...</h1>;

  if (isError) return <h1>{error.message}</h1>;

 

  const handleDelete = (UUID, index) => {
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert checklist!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        deleteChecklist(UUID).then(res => {
          if (res === true) {
            setChecklists([...checklists.slice(0, index), ...checklists.slice(index + 1)]);
            /*Swal.fire(
              'Deleted!',
              'Your checklist has been deleted.',
              'success'
            )*/
          } else {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Запись не существует или у вас нет прав на ее удаление'
            })

          }
        }).catch(err => {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err
          })

        })

      }
    })

  }



  const sortableModified = () => {
    let checklistsNew = [...checklists]
    if (!isSortableModified) {
      checklistsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateA - dateB

      })
    } else {
      checklistsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => {

        const dateAnew = a.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateBnew = b.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-');
        const dateA = Date.parse(dateAnew)
        const dateB = Date.parse(dateBnew)
        return dateB - dateA
        
      })
    } 
    setSortableModified(!isSortableModified);
    setSortableTitle(false);
    setSortableNumeric(false);
    setChecklists(checklistsNew);
  }

  const sortableTitle = () => {
    
    let checklistsNew = [...checklists]
    if (!isSortableTitle) {
      checklistsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => a.title.localeCompare(b.title));
    } else {
      checklistsNew.sort((a, b) => a.sort - b.sort).sort((a, b) => b.title.localeCompare(a.title));
    }
    setSortableTitle(!isSortableTitle)
    setSortableNumeric(false);
    setSortableModified(false);
    console.log([checklistsNew])
    setChecklists(checklistsNew);
  }

  const handleCreate = () => {
    createChecklist({name: 'Untitled checklist'}).then(res => {
      if (res.UUID !== undefined && res.UUID !== null) {
        setRedirect('/checklist/'+res.UUID)
      }
    }).catch(err => {
      alert(err)
    })
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="bodyContent100">
      <div className="liveContent0">
        <div className="flex acenter jbetween">
          <h1>Checklists</h1>
          <Button className="btn" variant="contained" onClick={handleCreate}>+ Create checklist</Button>
        </div>
        {isAdmin && (
          <div></div>
        )}

        <div className="table-default-wrap">
        <div className="table-default table-tr-pointer" cellSpacing="0" cellPadding="0">
          <div className="table-default-thead">
            <div className="table-default-tr flex acenter jbetween">
              <div className="table-default-th table-default-title flex acenter" onClick={sortableTitle}>Checklist {isSortableTitle ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th flex acenter" onClick={sortableModified}>Last modified {isSortableModified ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}</div>
              <div className="table-default-th">Scheduled</div>
              <div className="table-default-th">Access</div>
              <div className="table-default-th table-default-btns"></div>
              <div className="table-default-th table-default-btns"></div>
              <div className="table-default-th table-default-small"></div>
            </div>
          </div>
          <div className="table-default-tbody">
          
            {checklists?.map((checklistItem, index) => (

                <ChecklistCard 
                  key={checklistItem.UUID} 
                  index={checklistItem.index+1}
                  item={checklistItem} 
                  setRedirect={setRedirect} 
                  isAdmin={isAdmin} 
                  setLoading={setLoading} 
                  handleDelete={() => handleDelete(checklistItem.UUID, index)} 
                />

            ))}

          </div>
        </div>
        </div>

    </div>
    </div>
  );
};
