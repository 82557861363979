import React, { useState, useEffect, useRef } from 'react';
import Multiple from '../Types/Multiple';
import Text from '../Types/Text';
import Number from '../Types/Number';
import Checkbox from '../Types/Checkbox';
import DateTime from '../Types/DateTime';
import Media from '../Types/Media';
import Slider from '../Types/Slider';
import AddonNote from './Addons/Note';
import AddonMedia from './Addons/Media';
import { MdCollections } from "react-icons/md";
import axios from 'axios';
import { getMultipleVariants, updateQuestion } from '../Queries';

const ItemQuestion = (props) => {

	const [ isLoading, setLoading ] = useState(true)
	const [ multipleVariants, setMultipleVariants ] = useState([])
	const [ files, setFiles ] = useState([])

	const [ isActiveNote, setIsActiveNote ] = useState(false)
	const [ isActiveMediaBtn, setIsActiveMediaBtn ] = useState(true)
	const [ noteButtonText, setNoteButtonText ] = useState('Add note...')
	const [ noteText, setNoteText ] = useState(props.item.noteText ?? '')

	const [ selectedFile, setSelectedFile ] = useState(null)
	const [ loaded, setLoaded ] = useState(null)
	const fileInputRef = useRef();
	const blockRef = useRef(null);

	let url8000 = ''

	if (window.location.port === '') {
		url8000 = window.location.protocol+'\/\/'+window.location.hostname
	} else {
		url8000 = window.location.protocol+'\/\/'+window.location.hostname+':8000'
	}

	const uploadMediaFile = (event) => {
		setSelectedFile(event.target.files[0])
		setLoaded(0)
		const data = new FormData() 
	    data.append('file', event.target.files[0])
	    event.target.value = ''
	    setFiles([...files, {path: '1'}])

	    /*axios.post(url8000+"/upload", data, { 
      		// receive two    parameter endpoint url ,form data
		})
		.then(res => { // then print response status
		    console.log(res)
		    event.target.value = ''
		    setFiles([...files, {path: res.data.path.replace('public', '')}])
		})*/
	}
	
	useEffect(() => {
		props.refReview[props.item.UUID] = blockRef
		props.setRefReview(props.refReview)

		if (props.item !== undefined && props.item !== null && props.item.typeResponse !== undefined && props.item.typeResponse !== null && props.item.typeResponse.code === 'media') {
		  	setIsActiveMediaBtn(false)
		}
		if (props.item !== undefined && props.item !== null && props.item.typeResponse !== undefined && props.item.typeResponse !== null && props.item.typeResponse.code === 'multiple') {
		  	let groupMultipleUUID = ''

			if (props.item.groupMultiple.UUID !== undefined) {
				groupMultipleUUID = props.item.groupMultiple.UUID
			}
			if (props.item.groupMultiple[0] !== undefined) {
				groupMultipleUUID = props.item.groupMultiple[0].UUID
			}
			if (groupMultipleUUID !== '' && groupMultipleUUID !== undefined) {
				getMultipleVariants({ groupMultiple: groupMultipleUUID, removed: 0 }).then(items => {

					const variantsSort = items.sort((a, b) => a.sort - b.sort)
						    console.log('variantsSort')
					setMultipleVariants(variantsSort)

				}).catch(err => {})
			}

		}

		if (props.item.noteText === '') {
			setNoteButtonText('Add note...')
		} else {
			setNoteButtonText('Edit note...')
		}

		//console.log(props.quizQuestions)

	}, [props.item, props.quizQuestions])

	const saveNote = (e) => {
		e.preventDefault()
		const value = e.target[0].value
		setIsActiveNote(!isActiveNote)
		setNoteButtonText('Edit note...')

		setNoteText(value)
		// сохраняем noteText
		// только если рабочая версия
		if (props.work) {
			updateQuestion(props.item.UUID, {'noteText': value}).then((item) => {
				if (item.data.UUID !== null && item.data.UUID !== undefined) {
					props.setSaveResult(true)
				}
			}).catch(() => {
				props.setSaveResult(false)
			})
		}

		if (value === '') {
			setNoteButtonText('Add note...')
		}
	}
	const toggleNote = () => {
		setIsActiveNote(!isActiveNote)
	}


	let getContent = ''

	if (props.item !== undefined && props.item !== null && props.item.typeResponse !== undefined && props.item.typeResponse !== null) {
	switch(props.item.typeResponse.code) {
	    case 'multiple':
	        getContent = <Multiple key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'text':
	        getContent = <Text key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'number':
	        getContent = <Number key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'checkbox':
	        getContent = <Checkbox key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'dateTime':
	        getContent = <DateTime key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    case 'media':
	        getContent = <Media key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} files={files} setFiles={setFiles} fileInputRef={fileInputRef} />
	        break
	    case 'slider':
	        getContent = <Slider key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	    default:
	        getContent = <Text key={props.item.UUID} { ...props } isLoading={isLoading} setLoading={setLoading} multipleVariants={multipleVariants} setMultipleVariants={setMultipleVariants} />
	        break
	}
	}

    return (
        <>
        <div data-key={props.item.UUID} ref={blockRef}>
	        <div className={props.item.required ? "QuizContent QuizContent-Required" : "QuizContent"}>
	        	{ getContent }

		        {isActiveNote ? 
		         	<AddonNote isActiveNote={isActiveNote} 
		         	      setIsActiveNote={setIsActiveNote} 
		         	      toggleNote={toggleNote} 
		         	      saveNote={saveNote} 
		         	      noteText={noteText} 
		         	/> 
		         : ''
		     	}
		     	 
		         <AddonMedia { ...props } files={files} setFiles={setFiles} /> 

		     	 {!isActiveNote && noteText !== '' ? 
		         	<div className="QuizNote-text mt-2" onClick={toggleNote}>{ noteText }</div> 
		         : '' 
		         }
		         <div className="QuizContent__footer flex acenter jbetween">
		         	<div>
		         		<button className="QuizContent__btnSmall" onClick={toggleNote}>{noteButtonText}</button>
		         	</div>
		         	<div>

		         		{isActiveMediaBtn ? 
			         		<button className="QuizContent__btnSmall flex acenter" onClick={()=>fileInputRef.current.click()}>
			         		  <span className="flex acenter"><MdCollections /></span> Media
			         		</button>
		         		: ''}
		         		


						<input type="file" name="file" multiple={false} ref={fileInputRef} onChange={uploadMediaFile} hidden />
						 


		         	</div>
		         </div>
	        </div>
        </div>
        </>
    );
  
};

export default ItemQuestion