import React, { useState, useEffect } from 'react'
//import { useHistory } from "react-router";
import "./LoginPage.css";
import bcrypt from 'bcryptjs';
import {setTimeActive, isInApp, AuthAD, AuthToken, naumenGetGlobal} from '../../api/Naumen';
//import {AuthAD} from './LoginPageQuery';
import logo from '../../assets/images/logo_big.png'

export const Logined = ({
  setIsLoggedIn,
  setUserName,
  setIsAdmin
}) => {

    useEffect(() => {
        localStorage.removeItem('UUID')
        localStorage.removeItem('TOKEN')
        localStorage.removeItem('isLoggedIn')
        localStorage.removeItem('userFirstName')
        localStorage.removeItem('userLastName')
    }, [])

    //const history = useHistory()

    window.parent.injectJsApi(window.parent,window)

    const UUID = window.jsApi.getCurrentUser().uuid;

    naumenGetGlobal('employee$employee', {
      "UUID": UUID
    }).then(e => {

      const employee = e[0] ?? ''

      if (employee.length === 0) {

        alert('Пользователь, с таким логином, не существует или пароль неверный');
        return false
      }

      // Отправляем запрос на получение токена
      AuthToken(employee.login)
      .then(token => {
          if (token === 'error') {
            alert('Вы ввели логин или пароль не верно!');
          } else {
            localStorage.setItem('TOKEN', token);
            setIsAdmin(true);
            localStorage.setItem('isLoggedIn', true);
            localStorage.setItem('userLogin', employee.login);
            localStorage.setItem('UUID', employee.UUID);
            setIsLoggedIn(true);
            //history.push('/');
          }
      }).catch(er => {
            alert('Вы ввели логин или пароль не верно!');
      })


    }).catch(function (error) {
        alert('Пользователь не существует');
        return false
    })

}

export const LoginPage = ({
  setIsLoggedIn,
  setUserName,
  setIsAdmin
}) => {

useEffect(() => {
    localStorage.removeItem('UUID')
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('isLoggedIn')
    localStorage.removeItem('userFirstName')
    localStorage.removeItem('userLastName')
}, [])
//const history = useHistory()

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const isLoggedIn = localStorage.getItem("isLoggedIn") ?? false;
  // Если приложение встроенное, то авторизуем 
  // пользователя без вопросов

  if ( isInApp() && isLoggedIn === false) { 
    Logined({setIsLoggedIn,setUserName,setIsAdmin})
    return false
  }

  const handleLoginChange = (e) => {
    setLogin(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleLogIn = (e) => {
    e.preventDefault();
    naumenGetGlobal('employee$employee', {
      "login": login
    }).then(employee => {

      if (employee.length === 0) {
        
        alert('Неверный логин или пароль');
        return false

      }
      
      handleLogining(employee[0]);
      return false

    }).catch(function (error) {
        
        alert('Неверный логин или пароль');
        return false

    })
  }

  const handleLogining = (employee) => {
    
    if (login === employee.login && (employee.password === true || bcrypt.compareSync(password, employee.password) === true)) {

        AuthToken(employee.login)
        .then(token => {
          if (token === 'error') {
            alert('Вы ввели логин или пароль не верно!');
          } else {
            localStorage.setItem('TOKEN', token);
            setIsAdmin(true);
            localStorage.setItem('isLoggedIn', true);
            localStorage.setItem('userLogin', employee.login);
            localStorage.setItem('UUID', employee.UUID);
            setTimeActive()
            
            setIsLoggedIn(true);
            //window.location.reload()
            //history.push('/');
          }
        }).catch(er => {
            alert('Вы ввели логин или пароль не верно!');
        })

    } else {
        // Если пароль неверный
        // Пробуем проверить его в LDAP
        AuthAD(login, password).then(auth => {
          if (auth !== false) {
            handleLogining({ login, password: true });
            return false
          } else {
            alert('Неверный логин или пароль');
            return false
          }
        });
        return false
    }

  }

  return (
    <div className="loginMain">
      <form className="loginForm" onSubmit={handleLogIn}>
        
        <img src={logo} alt="Login" />
        <h2>Account sign-in</h2>

        <div>
          <input
            className="loginFormInput"
            type="text"
            placeholder="Логин"
            onChange={handleLoginChange}
            value={login}
            required
          />
        </div>
        <div>
          <input
            className="loginFormInput"
            type="password"
            placeholder="Пароль"
            onChange={handlePasswordChange}
            value={password}
            required
          />
        </div>
        <div>
          <button className="blackBtn" type="submit">
            Sign-In
          </button>
        </div>
      </form>
    </div>
  );
};
