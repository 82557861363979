import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete, MdDragIndicator } from "react-icons/md";
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import Swal from 'sweetalert2'
import MoreGroup from "./more/MoreGroup";
import ObjectsBlock from './ObjectsBlock'
import { IoIosArrowForward, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiPencilFill } from "react-icons/ri";
import { 
  deleteObjectGroup 
} from './ObjectQuery';

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

export const ObjectGroupItem = (props) => {

  const [ isActive, setActive ] = useState(false)
  const [ parentTable, setParentTable ] = useState('')

  const handlerToggleActive = () => {
    setParentTable(<div className="ObjectsBlock"><ObjectsBlock parent={ props.item.UUID } { ...props } /></div>)
    setActive(!isActive)
  }

  const handleDelete = () => {
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert Group!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        deleteObjectGroup(props.item.UUID).then(res => {
          if (res === true) {
            props.setObjectGroups([...props.objectGroups.slice(0, props.index), ...props.objectGroups.slice(props.index + 1)]);
            /*Swal.fire(
              'Deleted!',
              'Your checklist has been deleted.',
              'success'
            )*/
          } else {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Запись не существует или у вас нет прав на ее удаление'
            })

          }
        }).catch(err => {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err
          })

        })

      }
    })

  }

  const handlerGroupMoreClose = () => {

    props.setAreaRightContent('')
    props.setIsAreaRightActive(false)

  }
  
  const handlerGroupMore = () => {

    props.setAreaRightContent('')
    props.setAreaRightContent(<MoreGroup { ...props } handlerGroupMoreClose={handlerGroupMoreClose} />)
    props.setIsAreaRightActive(true)

  }

  return (
  		<div className="table-default-tr">
      <div className="flex jbetween">
            
            <div className="table-default-td table-default-title flex acenter">

              <div className="table-folders-handler" onClick={handlerToggleActive}>
                {isActive ? 
                  <IoIosArrowDown />
                : 
                  <IoIosArrowForward />
                }
              </div>  

              <div onClick={handlerGroupMore}>
                <div className="checklist-title">{props.item.title}</div>
                <div className="checklist-descritpion">{ props.item.description ?? '' }</div>
              </div>

            </div>

            <div className="table-default-td" onClick={handlerGroupMore}>{props.item.responsible === null
              ? 
              'All users'
              : props.item.responsible.title
            }</div>

            <div className="table-default-td" onClick={handlerGroupMore}>
            
            <ReactTimeAgo date={Date.parse(props.item.lastModifiedDate.replace(/ /g, 'T').replace(/\./g, '-'))} locale="en-US" />
            	
            </div>
            <div className="table-default-td" onClick={handlerGroupMore}>{props.item.adress ?? ''}</div>
            
            <div className="table-default-td table-default-btns-object">
              <div className="flex jend">
                <div className="btn-delete mr-1" onClick={() => props.handlerToggleFormAddGroup(props.item)}><RiPencilFill /></div>
                <div className="btn-delete" onClick={handleDelete}><MdDelete /></div>
              </div>
            </div>
           
      </div>
      {isActive ? 
        parentTable
       : ''
      }
      </div>

      

  );
};
