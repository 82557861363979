//import styles from "./Footer.module.css";

export const Footer = ({ isLoggedIn, year }) => {
  return (
    <>
    { isLoggedIn ? 
	    <footer>
	    </footer>
	    : ''
	}
    
    </>
  );
};
